import { CopyIndicator } from '../CopyIndicator/CopyIndicator';

interface Props {
  name: string;
  value: string;
}

export function APIKeyCard(props: Props) {
  return (
    <div key={props.name} className="flex h-8 w-full flex-row items-center gap-x-3 rounded-[8px] bg-[#F9F9F9] px-3">
      <div className="text-[12px] font-semibold">{props.name}</div>
      <div className="flex flex-row items-center gap-x-2">
        <div className="cursor-text select-text font-['Menlo'] text-[12px] font-medium">{props.value}</div>
        <CopyIndicator value={props.value} />
      </div>
    </div>
  );
}
