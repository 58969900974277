import { OperationLog, OperationLogType } from '../../../../../../../../../types/operationLog';

// Helper function to format duration in seconds to "Xmin Ys" format
function formatDuration(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  let formattedDuration = '';

  if (minutes > 0) {
    formattedDuration += `${minutes}min `;
  }
  formattedDuration += `${remainingSeconds}s`;
  return formattedDuration;
}

interface Props {
  operationLog: OperationLog;
}

export function OperationLogView(props: Props) {
  const log = props.operationLog;
  const label = log.operation_type === OperationLogType.Query ? 'Query Operation' : 'Index Operation';
  const dateTime = new Date((log.timestamp as any)['$date']);
  const timestampStr = dateTime.toLocaleTimeString() + ' on ' + dateTime.toDateString();

  return (
    <div className="flex flex-col rounded-[8px] border border-[#f3f3f3] bg-white p-2 text-[12px] font-medium text-ozoneV2-grey-500">
      <div className="text-[14px] font-semibold">{label}</div>
      <div className="pb-1 text-[10px] font-medium">{timestampStr}</div>
      <div>{log.tokens} tokens</div>
      <div>{formatDuration(log.duration)} duration</div>
      {log.metadata && Object.keys(log.metadata).length !== 0 && <div>{JSON.stringify(log.metadata)}</div>}
    </div>
  );
}
