import { Button } from '../Button/Button';
import { ButtonType } from '../Button/Button.types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UsageIndicator } from './components/UsageIndicator/UsageIndicator';
import { useSelector } from 'react-redux';
import { selectAllGraphs } from '../../../stores/GlobalGraphs/slice/selectors';
import { selectAppOrganizationMetadata } from '../../../stores/GlobalApp/slice/selectors';

export function PlanIndicator() {
  const navigate = useNavigate();
  const graphCount = useSelector(selectAllGraphs).length;
  const metadata = useSelector(selectAppOrganizationMetadata);
  const storageMb = (metadata?.documents_storage_size_kb ?? 0) / 1000;
  const readUnits = metadata?.total_read_units;
  const writeUnits = metadata?.total_write_units;

  const onUpgrade = useCallback(() => {
    navigate('/plans');
  }, [navigate]);

  return (
    <div className="_PlanIndicator flex w-full flex-col rounded-[12px] border border-[#f3f3f3] bg-[#f9f9f9] p-2">
      <div className="_Header text-[16px] font-semibold">Free tier</div>
      <div className="_Content flex flex-col gap-y-1.5 py-2">
        <UsageIndicator label="Graphs" maxValue={3} value={graphCount} unit={''} />
        <UsageIndicator label="Storage" maxValue={10} value={storageMb} unit={'MB'} />
        <UsageIndicator label="Reads" maxValue={1000} value={readUnits ?? 0} unit={''} />
        <UsageIndicator label="Writes" maxValue={1000} value={writeUnits ?? 0} unit={''} />
      </div>
      <Button label="Upgrade now" type={ButtonType.PRIMARY} onClick={onUpgrade} />
    </div>
  );
}
