import { TabHeader } from '../../components/TabHeader/TabHeader';
import { TabContent } from '../../components/TabContent/TabContent';
import { TabView } from '../../components/TabView/TabView';
import { PlanCard } from './components/PlanCard/PlanCard';
import { useSelector } from 'react-redux';
import { selectAppOrganizationId, selectAppPlan } from '../../../../../stores/GlobalApp/slice/selectors';
import { useCallback } from 'react';
import { SubscriptionPlan } from '../../../../../types/organization';
import { getCheckoutUrl } from '../../../../../helpers/api/getCheckoutUrl';
import { selectUserId } from '../../../../../stores/GlobalUser/slice/selectors';
import { changePlan } from '../../../../../helpers/api/changePlan';
import { getInTouch } from '../../../../../helpers/api/getInTouch';

export function PlansTab() {
  const currentPlan = useSelector(selectAppPlan);
  const organizationId = useSelector(selectAppOrganizationId);
  const userId = useSelector(selectUserId);

  const onChangePlan = useCallback(
    (plan: SubscriptionPlan) => {
      if (!organizationId) return;

      if (plan === SubscriptionPlan.STARTER) {
        changePlan(userId, organizationId, 'STARTER').then(() => alert('Successfully cancelled subscription!'));
      }

      if (plan === SubscriptionPlan.STANDARD) {
        getCheckoutUrl(userId).then(checkoutUrl => (window.location.href = checkoutUrl));
      }

      if (plan === SubscriptionPlan.ENTERPRISE) {
        getInTouch(userId, organizationId).then(() =>
          alert('We reached out to you via email and will be in touch within 12 hours!'),
        );
      }
    },
    [organizationId, userId],
  );

  return (
    <TabView>
      <TabHeader>
        <div className="text-[16px] font-semibold">Plans</div>
      </TabHeader>
      <TabContent>
        <div className="flex h-full flex-row justify-center gap-x-3 p-5">
          <PlanCard
            label="Starter"
            isCurrent={currentPlan === SubscriptionPlan.STARTER}
            description="100% Free, for small projects."
            maxGraphs={3}
            maxReads={1000}
            maxWrites={1000}
            maxNamespaces={5}
            maxStorage={10}
            addOns={['Community Access']}
            onClick={() => onChangePlan(SubscriptionPlan.STARTER)}
            buttonLabel={currentPlan !== SubscriptionPlan.STARTER ? 'Downgrade' : undefined}
          />
          <PlanCard
            label="Standard"
            isCurrent={currentPlan === SubscriptionPlan.STANDARD}
            description="Starting at $299/month, for production-grade projects."
            addOns={['Community Access', 'Prioritized performance', 'Multi-user collaboration', '24/7 Support']}
            onClick={() => onChangePlan(SubscriptionPlan.STANDARD)}
            storageRate={0.0008}
            readsRate={0.28}
            writesRate={1.11}
            buttonLabel={currentPlan !== SubscriptionPlan.ENTERPRISE ? 'Upgrade' : undefined}
          />
          <PlanCard
            label="Enterprise"
            isCurrent={currentPlan === SubscriptionPlan.ENTERPRISE}
            description="Custom pricing, for mission-critical apps and services."
            addOns={[
              'Community Access',
              'Prioritized performance',
              'Multi-user collaboration',
              '24/7/365 Support',
              'Enterprise-Grade Security',
              'Metrics',
              'Graph Visualization',
              'Uptime SLA',
            ]}
            onClick={() => onChangePlan(SubscriptionPlan.ENTERPRISE)}
            storageRate={0.0016}
            readsRate={0.56}
            writesRate={2.22}
            buttonLabel={currentPlan === SubscriptionPlan.ENTERPRISE ? undefined : 'Contact Us'}
          />
        </div>
      </TabContent>
    </TabView>
  );
}
