import { fetchApi } from '../fetchApi';

export async function deleteDataSource(organizationId: string, dataSourceId: string): Promise<string> {
  try {
    const data = await fetchApi('delete_data_source', {
      organization_uuid: organizationId,
      data_source_uuid: dataSourceId,
    });
    return data.data_source_uuid;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
