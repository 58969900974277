import { ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  label: string;
  isActive: boolean;
  onClick: () => void;
}

export function MenuTab(props: Props) {
  const bgHoverClass = !props.isActive ? 'hover:bg-[#F5F9FF]' : '';
  const bgActiveClass = props.isActive ? 'bg-[#ECF2FF]' : '';
  const textActiveClass = props.isActive ? 'text-[#4764FF]' : '';

  return (
    <div
      className={`relative w-full cursor-pointer ${bgHoverClass} ${bgActiveClass} rounded-[8px] transition-transform duration-200 active:scale-95`}
      onClick={props.onClick}
    >
      <div className="flex h-5.5 w-full flex-row items-center gap-x-1.5 px-1.5">
        <div className={`_Icon size-2 ${textActiveClass}`}>{props.icon}</div>
        <div className={`_Icon size-2 ${textActiveClass} mb-[4px] w-full text-[14px] font-medium`}>{props.label}</div>
      </div>
    </div>
  );
}
