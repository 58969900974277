import { GraphIndexInterval } from '../../types/graph';
import { fetchApi } from '../fetchApi';

export async function createGraph(
  organizationId: string,
  name: string,
  indexInterval: GraphIndexInterval,
): Promise<string> {
  try {
    const data = await fetchApi('create_graph', {
      organization_uuid: organizationId,
      name: name,
      index_interval: indexInterval,
    });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
