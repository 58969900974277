export enum DataSourceStatus {
  HEALTHY = 'HEALTHY',
  FAILURE = 'FAILURE',
}

export enum DataSourceType {
  AWS_S3 = 'AWS_S3',
  GOOGLE_DRIVE = 'GOOGLE_DRIVE',
  AZURE_BLOB = 'AZURE_BLOB',
  DROPBOX = 'DROPBOX',
  NOTION = 'NOTION',
  GOOGLE_CLOUD = 'GOOGLE_CLOUD',
}

export interface AWSConfiguration {
  bucket_name: string;
  region: string;
  access_key_id?: string;
  secret_access_key?: string;
}

export interface GoogleDriveConfiguration {
  folder_id: string;
  client_id: string;
  client_secret: string;
}

export interface AzureBlobConfiguration {
  container_name: string;
  connection_string: string;
}

export interface DropboxConfiguration {
  folder_path: string;
  access_token: string;
}

export interface NotionConfiguration {
  database_id: string;
  api_key: string;
}

export interface GoogleCloudConfiguration {
  bucket_name: string;
  project_id: string;
  service_account_json: string;
}

export type DataSourceConfiguration =
  | AWSConfiguration
  | GoogleDriveConfiguration
  | AzureBlobConfiguration
  | DropboxConfiguration
  | NotionConfiguration
  | GoogleCloudConfiguration;

export interface DataSource {
  uuid: string;
  organization_uuid: string;
  name: string;
  created_at: Date;
  source_type: DataSourceType;
  configuration: DataSourceConfiguration;
  status: DataSourceStatus;
}
