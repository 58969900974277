import { Organization } from '../../types/organization';
import { fetchApi } from '../fetchApi';

export async function updateOrganization(
  organizationId: string,
  organizationUpdate: Partial<Organization>,
): Promise<string> {
  try {
    const data = await fetchApi('update_organization', {
      organization_uuid: organizationId,
      organization: organizationUpdate,
    });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
