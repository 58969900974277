import { useSelector } from 'react-redux';
import { selectAppOrganizationId } from '../../../../../../../stores/GlobalApp/slice/selectors';
import { useCallback, useEffect, useState } from 'react';
import { listDataConnectors } from '../../../../../../../helpers/api/listDataConnectors';
import { useParams } from 'react-router-dom';
import { DataConnector } from '../../../../../../../types/dataconnector';
import { DataConnectorCard } from '../../../../../../components/DataConnectorCard/DataConnectorCard';
import { LoadingCircle } from '../../../../../../components/LoadingCircle/LoadingCircle';

export function GraphDataConnectors() {
  const organizationId = useSelector(selectAppOrganizationId);
  const { graphId } = useParams();
  const [dataConnectors, setDataConnectors] = useState<Array<DataConnector> | null>(null);

  const fetchDataConnectors = useCallback(() => {
    if (!organizationId) return;
    if (!graphId) return;
    listDataConnectors(organizationId, graphId).then(res => setDataConnectors(res));
  }, [graphId, organizationId]);

  const handleReload = useCallback(() => {
    fetchDataConnectors();
  }, [fetchDataConnectors]);

  useEffect(() => {
    fetchDataConnectors();
  }, [fetchDataConnectors, organizationId]);

  return (
    <div className="flex size-full flex-col gap-y-1">
      {(dataConnectors === null || dataConnectors?.length === 0) && (
        <div className="flex size-full w-full flex-col items-center justify-center gap-y-1">
          {dataConnectors === null && <LoadingCircle />}
          {dataConnectors !== null && dataConnectors?.length === 0 && (
            <div className="flex max-w-[50%] text-center">
              Data connectors are currently in beta testing and not supported on your plan. Please reach out to support
              to request a specific integration (AWS S3, Azure Blob Storage, etc.) and we will be glad to assist!
            </div>
          )}
        </div>
      )}
      {dataConnectors &&
        dataConnectors.map(dataConnector => (
          <DataConnectorCard key={dataConnector.uuid} dataConnector={dataConnector} reload={handleReload} />
        ))}
    </div>
  );
}
