import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer } from '../../../utils/redux/redux-injectors';
import { AppState } from './types';
import { Organization } from '../../../types/organization';
import { LoadingStatus } from '../../GlobalUser/slice/types';

export const initialState: AppState = {
  activeOrganizationId: null,
  organizations: null,
  status: LoadingStatus.IDLE,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateActiveOrganizationId(state, action: PayloadAction<string>) {
      state.activeOrganizationId = action.payload;
    },
    updateOrganizations(state, action: PayloadAction<Array<Organization>>) {
      state.organizations = action.payload;
    },
    updateStatus(state, action: PayloadAction<LoadingStatus>) {
      state.status = action.payload;
    },
  },
});

export const { actions: appActions } = slice;

export const useAppState = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
