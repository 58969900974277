import { fetchApi } from '../fetchApi';

export async function acceptInvite(userId: string, token?: string): Promise<void> {
  try {
    const data = await fetchApi('accept_invite', { user_uuid: userId, token: token });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
