import { useState } from 'react';
import { ArrowRightIcon } from '../Icons';

interface DropdownProps {
  options: string[];
  defaultLabel: string;
  onSelect: (option: string) => void;
  icon?: React.ReactNode;
  disabled?: boolean;
  rollUp?: boolean; // New prop to control the dropdown direction
}

export function Dropdown({ options, defaultLabel, onSelect, icon, disabled, rollUp = false }: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultLabel);

  const baseClasses =
    'px-1.5 py-1 rounded-[6px] font-medium text-[13px] focus:outline-none duration-150 flex flex-row gap-x-1.5 items-center justify-between cursor-pointer';
  const dropdownClasses = `${baseClasses} ${disabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-gray-100 text-[#4e4e4e] hover:bg-gray-200'}`;
  const menuClasses = `absolute w-full bg-[#fafafa] rounded-[6px] overflow-hidden z-10 ${rollUp ? 'bottom-full mb-1' : 'top-full mt-1'}`;

  const handleDropdownToggle = () => {
    if (!disabled) {
      setIsOpen(prev => !prev);
    }
  };

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  return (
    <div className="relative">
      <div className={dropdownClasses} onClick={handleDropdownToggle}>
        {icon}
        <span>{selectedOption}</span>
        <span className={`ml-2 ${isOpen ? '-rotate-90' : 'rotate-90'}`}>
          <ArrowRightIcon className="size-1.5" />
        </span>
      </div>
      {isOpen && !disabled && (
        <ul className={menuClasses}>
          {options.map(option => (
            <li
              key={option}
              className="cursor-pointer px-[10px] py-[6px] text-[13px] hover:bg-gray-100"
              onClick={() => handleOptionSelect(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
