export const downloadXMLFile = (fileStr: string, exportedFilename: string) => {
  // Create a Blob from the file string (in this case, the XML content)
  const blob = new Blob([fileStr], { type: 'application/xml' });

  // Create a link element and trigger the download
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = exportedFilename;
  document.body.appendChild(a); // Append the link to the document body
  a.click(); // Programmatically click the link to trigger the download
  a.remove(); // Remove the link element after downloading
  window.URL.revokeObjectURL(url); // Release the URL object
};
