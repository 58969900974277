import { GraphStatus } from '../../../../../types/graph';

interface Props {
  status: GraphStatus;
}

const dotStyle = {
  height: '10px',
  width: '10px',
  backgroundColor: 'yellow',
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: '8px',
};

export function GraphStatusView(props: Props) {
  let statusText = '';
  let statusColor = '';
  switch (props.status) {
    case GraphStatus.STARTING:
      statusText = 'Starting';
      statusColor = 'orange';
      break;
    case GraphStatus.STOPPING:
      statusText = 'Stopping';
      statusColor = 'orange';
      break;
    case GraphStatus.ONLINE:
      statusText = 'Online';
      statusColor = 'green';
      break;
    case GraphStatus.OFFLINE:
      statusText = 'Offline';
      statusColor = 'red';
      break;
    case GraphStatus.INDEXING:
      statusText = 'Indexing';
      statusColor = 'orange';
      break;
    case GraphStatus.SCHEDULED_INDEXING:
      statusText = 'Scheduled indexing';
      statusColor = 'orange';
      break;
    case GraphStatus.FAILED:
      statusText = 'Operation failed';
      statusColor = 'red';
      break;
  }

  const cls = [GraphStatus.INDEXING, GraphStatus.SCHEDULED_INDEXING].includes(props.status) ? 'animate-pulse' : '';

  return (
    <div className="flex flex-row items-center text-[11px] font-semibold text-ozoneV2-grey-300">
      <span
        style={{
          height: '10px',
          width: '10px',
          backgroundColor: statusColor,
          borderRadius: '50%',
          display: 'inline-block',
          marginRight: '6px',
        }}
        className={cls}
      ></span>
      {statusText}
    </div>
  );
}
