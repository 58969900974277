import discordLogo from './assets/discord_logo.png';

export function CommunityIndicator() {
  return (
    <a
      href="https://discord.gg/EccDJdy3da"
      className="_PlanIndicator flex w-full cursor-pointer flex-row items-center gap-x-2 rounded-[12px] border border-[#f3f3f3] bg-[#f9f9f9] px-2 py-1.5 hover:bg-[#f1f1f1] active:bg-[#eeeeee]"
    >
      <img src={discordLogo} alt="logoicon" className="h-2.5" />
      <div className="_Text text-[14px] font-medium">Join the community</div>
    </a>
  );
}
