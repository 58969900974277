import { ConversationHistory } from '../../types/conversationHistory';
import { fetchApi } from '../fetchApi';

export async function getConversationHistory(graphId: string): Promise<ConversationHistory | null> {
  try {
    const data = await fetchApi('get_conversation', { graph_uuid: graphId });

    // If the response status is 404, return null (handled in the fetchApi function)
    if (data.status === 404) {
      return null;
    }

    return data;
  } catch (error: any) {
    if (error.message === 'Error: 404') {
      return null;
    }

    console.error('Error:', error);
    throw error;
  }
}
