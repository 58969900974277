import { NotificationsState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generateToast } from './helpers';
import { useInjectReducer } from '../../../utils/redux/redux-injectors';

export const initialState: NotificationsState = {
  toasts: [],
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<{ message: string; icon?: string }>) => {
      const newToast = generateToast(action.payload.message, action.payload.icon);
      state.toasts.push(newToast);
    },
    removeToast: (state, action: PayloadAction<string>) => {
      state.toasts = state.toasts.filter(toast => toast.id !== action.payload);
    },
  },
});

export const { actions: notificationsActions } = slice;

export const useNotificationsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
