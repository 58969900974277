import { fetchApi } from '../fetchApi';

export async function deleteDataConnector(organizationId: string, dataConnectorId: string): Promise<string> {
  try {
    const data = await fetchApi('delete_data_connector', {
      organization_uuid: organizationId,
      data_connector_uuid: dataConnectorId,
    });
    return data.data_connector_uuid;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
