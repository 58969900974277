import { ResourcesConfig } from '@aws-amplify/core';
import { APP_URL, IS_LOCALHOST } from './helpers/envHelper';

const awsExports: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: IS_LOCALHOST ? 'us-east-1_tyxBAlGn6' : 'us-east-1_QBE028sJd',
      userPoolClientId: IS_LOCALHOST ? '4fmjqids3tpl5rjd9msdhbl6q5' : '37jveltf2t9rdhna6cp3ifgfb',
      signUpVerificationMethod: 'link',
      loginWith: {
        email: true,
        oauth: {
          domain: IS_LOCALHOST
            ? 'artifact-webapp-dev.auth.us-east-1.amazoncognito.com'
            : 'artifact-webapp.auth.us-east-1.amazoncognito.com',
          scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: [APP_URL + '/login_successful'],
          redirectSignOut: [APP_URL + '/signout'],
          responseType: 'code',
          providers: ['Google'],
        },
      },
    },
  },
};

console.info(`EXPORTING COGNITO AUTH CONFIG`, awsExports);
export default awsExports;
