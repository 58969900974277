import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../thunks/fetchUser';

export function useFetchUser() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser() as any);
  }, []);
}
