import { RootState } from '../../../types/redux/RootState';
import { initialState } from './index';
import { createSelector } from '@reduxjs/toolkit';

const selectSlice = (state: RootState) => state?.graphs || initialState;

const selectGraphs = createSelector([selectSlice], state => state);

export const selectAllGraphs = createSelector([selectSlice], state =>
  state.graphs.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
);
export const selectGraphsLoadingStatus = createSelector([selectSlice], state => state.status);
