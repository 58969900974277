import { ValueView } from '../../../../../../components/ValueView/ValueView';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAllGraphs } from '../../../../../../../stores/GlobalGraphs/slice/selectors';
import { useEffect, useMemo, useState } from 'react';
import { CopyIndicator } from '../../../../../../components/CopyIndicator/CopyIndicator';
import { updateGraph } from '../../../../../../../helpers/api/updateGraph';
import { Graph } from '../../../../../../../types/graph';
import { PartialDeep } from 'type-fest';
import { ArrowRightIcon } from '../../../../../../components/Icons';

export function GraphOverview() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { graphId } = useParams();
  const allGraphs = useSelector(selectAllGraphs);
  const graph = useMemo(() => {
    return allGraphs.find(g => g.uuid === graphId);
  }, [allGraphs, graphId]);
  const graphName = graph?.name;
  const graphStatus = graph?.status;

  // State to manage entity types
  const [entityTypes, setEntityTypes] = useState<string[]>([]);
  const [newEntityType, setNewEntityType] = useState<string>('');

  const handleAddEntityType = () => {
    if (newEntityType.trim() !== '' && !entityTypes.includes(newEntityType.trim())) {
      if (!graphId) return;
      setEntityTypes([...entityTypes, newEntityType.trim()]);
      setNewEntityType('');
      const newEntityTypes = [...entityTypes, newEntityType];
      const graphUpdate: PartialDeep<Graph> = {
        index_config: {
          entity_extraction: {
            entity_types: newEntityTypes,
          },
        },
      };
      updateGraph(graphId, graphUpdate);
    }
  };

  const handleRemoveEntityType = (typeToRemove: string) => {
    if (!graphId) return;
    setEntityTypes(entityTypes.filter(type => type !== typeToRemove));
    const newEntityTypes = entityTypes.filter(et => et !== typeToRemove);
    const graphUpdate: PartialDeep<Graph> = {
      index_config: {
        entity_extraction: {
          entity_types: newEntityTypes,
        },
      },
    };
    updateGraph(graphId, graphUpdate);
  };

  useEffect(() => {
    if (!graph) return;
    if (!graph?.index_config) return;
    setEntityTypes(graph?.index_config?.entity_extraction.entity_types);
  }, [graph, graph?.index_config?.entity_extraction.entity_types]);

  return (
    <div className="mt-3 flex w-full flex-col">
      <ValueView value={graphName ?? ''} label="Graph name" action={<CopyIndicator value={graphName ?? ''} />} />
      <ValueView value={graphStatus ?? ''} label="Graph status" />
      <ValueView value={graphId ?? ''} label="Graph ID" action={<CopyIndicator value={graphId ?? ''} />} />

      {/* Index Configuration Section */}
      <div className="mt-5">
        <h3
          className="flex cursor-pointer items-center text-[16px] font-semibold"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          Settings
          <ArrowRightIcon
            className={`ml-1 size-1.5 transition-transform duration-300 ${isCollapsed ? 'rotate-90' : '-rotate-90'}`}
          />
        </h3>
        {!isCollapsed && (
          <div className="mt-3">
            <div className="flex items-center">
              <input
                type="text"
                value={newEntityType}
                onChange={e => setNewEntityType(e.target.value)}
                className="mr-2 rounded border p-2"
                placeholder="Enter entity type"
              />
              <button onClick={handleAddEntityType} className="rounded bg-blue-500 p-2 text-white">
                Add
              </button>
            </div>
            <div className="mt-3">
              {entityTypes.map((type, index) => (
                <div key={index} className="flex items-center justify-between border-b p-2">
                  <span>{type}</span>
                  <button onClick={() => handleRemoveEntityType(type)} className="text-red-500 hover:text-red-700">
                    x
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
