export enum TeamMemberStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
}

export enum TeamMemberRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  DEVELOPER = 'DEVELOPER',
}

export interface TeamMember {
  user_uuid: string;
  name: string;
  email: string;
  status: TeamMemberStatus;
  created_at: Date;
  role: TeamMemberRole;
}
