import { API_URL } from './envHelper';
import { getJwtToken } from './getJwtToken';

export async function fetchApi(endpoint: string, body: object = {}): Promise<any> {
  const jwtToken = getJwtToken();
  const res = await fetch(API_URL + '/' + endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`,
    },
    body: JSON.stringify(body),
  });

  if (!res.ok) {
    throw new Error(`Error: ${res.status}`);
  }

  return await res.json();
}
