import { Graph } from '../../types/graph';
import { fetchApi } from '../fetchApi';

export async function getGraphs(organizationId: string): Promise<Array<Graph>> {
  try {
    let data = await fetchApi('get_graphs', { organization_uuid: organizationId });

    // If the response status is 404, return an empty array (handled in the fetchApi function)
    if (data.status === 404) {
      return [];
    }

    // Make sure to convert date
    data = data.map((graph: any): Graph => {
      return { ...graph, created_at: new Date(graph.created_at.$date).toISOString() };
    });

    return data;
  } catch (error: any) {
    if (error.message === 'Error: 404') {
      return [];
    }

    throw new Error(`Error while fetching graphs: ${error.message}`);
  }
}
