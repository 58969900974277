import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../types/redux/RootState';
import { appActions } from '../slice';
import { selectUserId } from '../../GlobalUser/slice/selectors';
import { LoadingStatus } from '../../GlobalUser/slice/types';
import { createOrganization } from '../../../helpers/api/createOrganization';
import { fetchOrganizations } from './fetchOrganizations';

export function createAndSelectOrganization() {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const state = getState();
    const userId = selectUserId(state);
    if (!userId) return;
    createOrganization(userId)
      .then(organization => {
        localStorage.setItem('activeOrganizationId', organization.uuid);
        dispatch(fetchOrganizations());
        dispatch(appActions.updateActiveOrganizationId(organization.uuid));
      })
      .catch(e => dispatch(appActions.updateStatus(LoadingStatus.FAILED)));
  };
}
