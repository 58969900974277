import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { Button } from '../Button/Button';
import { ButtonType } from '../Button/Button.types';
import { DataSourceStatusView } from './components/DataSourceStatusView/GraphStatusView';
import { DataSource, DataSourceType } from '../../../types/datasource';
import { AWSS3Icon, AzureIcon, GoogleCloudIcon, GoogleDriveIcon, NotionIcon, DropboxIcon } from '../Icons';
import { deleteDataSource } from '../../../helpers/api/deleteDataSource';
import { useSelector } from 'react-redux';
import { selectAppOrganizationId } from '../../../stores/GlobalApp/slice/selectors';

const getDataSourceIcon = (sourceType: DataSourceType): string => {
  switch (sourceType) {
    case DataSourceType.AWS_S3:
      return AWSS3Icon;
    case DataSourceType.NOTION:
      return NotionIcon;
    case DataSourceType.GOOGLE_CLOUD:
      return GoogleCloudIcon;
    case DataSourceType.GOOGLE_DRIVE:
      return GoogleDriveIcon;
    case DataSourceType.DROPBOX:
      return DropboxIcon;
    case DataSourceType.AZURE_BLOB:
    default:
      return AzureIcon;
  }
};

interface Props {
  dataSource: DataSource;
  reload: () => void;
}

export function DataSourceCard(props: Props) {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const dataSource = props.dataSource;
  const organizationId = useSelector(selectAppOrganizationId);

  let dataSourceIcon = getDataSourceIcon(dataSource.source_type);

  const onDelete = useCallback(() => {
    if (!organizationId) return;
    deleteDataSource(organizationId, props.dataSource.uuid).then(() => {
      props.reload();
    });
  }, [organizationId, props]);

  return (
    <div
      key={dataSource.uuid}
      className="flex w-full flex-row items-center justify-between gap-x-3 rounded-[8px] border border-[#f3f3f3] bg-white px-3 py-2 hover:bg-[#fcfcfc]"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="_Left flex flex-row gap-x-3">
        <div className="_Left1 flex flex-col items-center justify-center">
          <img src={dataSourceIcon} alt={'datasourceIcon'} className="h-3" />
        </div>
        <div className="_Left2 flex flex-col gap-y-1">
          <div className="flex w-full flex-row justify-between gap-x-1">
            <div className="text-[14px] font-semibold">{dataSource.name}</div>
          </div>
          <div className="_Properties flex flex-col gap-y-0.25">
            <DataSourceStatusView status={dataSource.status} />
          </div>
        </div>
      </div>
      {hover && (
        <div className="_Right flex flex-row items-center gap-x-1">
          <Button label="Delete" onClick={onDelete} type={ButtonType.SECONDARY}></Button>
        </div>
      )}
    </div>
  );
}
