import { fetchApi } from '../fetchApi';

// Returns graph graphml XML as string
export async function getGraphFile(graphId: string): Promise<string> {
  try {
    const data = await fetchApi('get_graph_file', {
      graph_uuid: graphId,
    });
    return data.content;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
