import { Message } from './message';
import { v4 } from 'uuid';

export interface ConversationHistory {
  uuid: string;
  graph_uuid: string;
  messages: Array<Message>;
  created_at?: string;
}

export const generateDefaultConversationHistory = (): ConversationHistory => {
  return { uuid: v4(), messages: [], graph_uuid: '' };
};
