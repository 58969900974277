import { HorizontalMenu } from '../../../../../../components/HorizontalMenu/HorizontalMenu';
import { GraphChatInterface } from '../GraphChatInterface/GraphChatInterface';
import { GraphVisualization } from '../GraphVisualization/GraphVisualization';
import { GraphOperations } from '../GraphOperations/GraphOperations';
import { GraphDataConnectors } from '../GraphDataConnectors/GraphDataConnectors';
import { GraphTab } from '../../GraphsTab';
import { GraphUploadData } from '../GraphUploadData/GraphUploadData';
import { GraphOverview } from '../GraphOverview/GraphOverview';

interface Props {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

export function GraphDetail(props: Props) {
  return (
    <div className="relative flex size-full flex-col">
      <HorizontalMenu labels={Object.values(GraphTab)} active={props.activeTab} onChange={props.setActiveTab} />
      <div className="size-full overflow-y-scroll">
        <div className="flex size-full">
          {props.activeTab === GraphTab.Overview && (
            <div className="flex size-full flex-col">
              <GraphOverview />
            </div>
          )}

          {props.activeTab === GraphTab.Chat && (
            <div className="flex size-full flex-col">
              <GraphChatInterface />
            </div>
          )}

          {props.activeTab === GraphTab.Visualization && (
            <div className="flex size-full flex-col">
              <GraphVisualization />
            </div>
          )}

          {props.activeTab === GraphTab.Logs && (
            <div className="flex size-full flex-col">
              <GraphOperations />
            </div>
          )}

          {props.activeTab === GraphTab.DataConnectors && (
            <div className="flex size-full flex-col">
              <GraphDataConnectors />
            </div>
          )}

          {props.activeTab === GraphTab.UploadData && (
            <div className="flex size-full flex-col">
              <GraphUploadData />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
