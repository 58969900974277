import { fetchUserAttributes } from '@aws-amplify/auth';

export interface UserAttributes {
  uuid: string;
  name: string;
  email: string;
  picture: string;
}

export const getUserAttributes = async () => {
  try {
    const userAttributes = await fetchUserAttributes();
    const attributes: UserAttributes = {
      uuid: userAttributes.sub ?? '',
      email: userAttributes.email ?? '',
      name: userAttributes.name ?? '',
      picture: userAttributes.picture ?? '',
    };

    return attributes;
  } catch (error) {
    console.info('Error fetching user attributes:', error);
  }
};
