import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCognitoUserName, selectCognitoUserPicture } from '../../../stores/GlobalUser/slice/selectors';
import { Modal } from '../Modal/Modal';
import { AccountModal } from '../AccountModal/AccountModal';

export function Account() {
  const [open, setOpen] = useState(false);
  const pictureRef = useRef<HTMLImageElement | null>(null);
  const name = useSelector(selectCognitoUserName);
  const picture = useSelector(selectCognitoUserPicture);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className="_Account flex size-3 cursor-pointer rounded-[100px] bg-[#2F2F2F]" onClick={handleOpen}>
        {picture && (
          <img ref={pictureRef} src={picture} alt="user profile" className="size-3 overflow-hidden rounded-[100px]" />
        )}
      </div>
      <Modal open={open} onClose={handleClose}>
        <AccountModal />
      </Modal>
    </>
  );
}
