import { Dispatch } from '@reduxjs/toolkit';
import { userActions } from '../slice';
import { LoadingStatus } from '../slice/types';
import { getUserAttributes } from '../../../helpers/getUserAttributes';
import { getJwtToken } from '../../../helpers/authHelper';

export function loadCognitoUser() {
  return async (dispatch: Dispatch<any>) => {
    dispatch(userActions.updateCognitoLoadingStatus(LoadingStatus.LOADING));
    getUserAttributes()
      .then(attributes => {
        if (!attributes) {
          dispatch(userActions.updateCognitoLoadingStatus(LoadingStatus.FAILED));
          return;
        }

        // Load JWT user token
        getJwtToken()
          .then(jwtToken => {
            if (jwtToken) {
              dispatch(userActions.setUserJwtToken(jwtToken));
            }
            dispatch(userActions.setUserAttributes(attributes));
            dispatch(userActions.updateCognitoLoadingStatus(LoadingStatus.SUCCEEDED));
          })
          .catch(() => {
            dispatch(userActions.updateCognitoLoadingStatus(LoadingStatus.FAILED));
          });
      })
      .catch(() => {
        return dispatch(userActions.updateCognitoLoadingStatus(LoadingStatus.FAILED));
      });
  };
}
