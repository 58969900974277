import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { RoutesSwitch } from './components/RoutesSwitch/RoutesSwitch';
import { GlobalStores } from '../stores';
import { useLoadCognitoUser } from '../stores/GlobalUser/hooks/useLoadCognitoUser';
import { useLoadUser } from '../stores/GlobalUser/hooks/useLoadUser';
import { ToastContainer } from './components/ToastContainer/ToastContainer';

export function App() {
  const { i18n } = useTranslation();
  useLoadCognitoUser();
  useLoadUser();

  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s" defaultTitle="Artifact Console" htmlAttributes={{ lang: i18n.language }}>
        <meta name="description" content="Artifact Console" />
      </Helmet>

      <GlobalStores />
      <RoutesSwitch />
      <ToastContainer />
    </BrowserRouter>
  );
}
