export const MSAPage = () => {
  return (
    <div className="size-full overflow-y-scroll">
      <div className="mx-auto max-w-4xl select-text rounded-lg bg-white p-8 shadow-md">
        <h1 className="mb-1 text-2xl font-bold">Master Subscription Agreement</h1>
        <div className="mb-6 text-sm font-medium">Last updated on August 3, 2024</div>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">Introduction</h2>
          <p>
            This Master Subscription Agreement ("Agreement") is made and entered into as of the Effective Date (defined
            below) by and between Ozone Technologies Inc., a Delaware corporation, having its principal place of
            business at 1475 Folsom St, Ste #100, San Francisco, CA 94105, USA ("Company"), and the client identified
            below ("Client"). Company and Client are sometimes referred to herein individually as a "Party" and
            collectively as the "Parties."
          </p>
        </section>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">1. Definitions</h2>
          <p>
            <strong>1.1 "Effective Date"</strong> means the date Client accepts this Agreement.
          </p>
          <p>
            <strong>1.2 "Services"</strong> means the subscription services provided by Company as described in Exhibit
            A.
          </p>
          <p>
            <strong>1.3 "Deliverables"</strong> means the items to be delivered to Client as described in Exhibit A.
          </p>
        </section>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">2. Subscription Services</h2>
          <p>
            <strong>2.1 Provision of Services</strong>. Company shall provide the Subscription Services and Deliverables
            to Client as set forth in Exhibit A.
          </p>
          <p>
            <strong>2.2 Service Levels</strong>. For Enterprise clients, Company shall use commercially reasonable
            efforts to meet the service levels specified in Exhibit B.
          </p>
        </section>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">3. Subscription Term and Termination</h2>
          <p>
            <strong>3.1 Subscription Term</strong>. This Agreement shall commence on the Effective Date and shall
            continue until terminated as provided herein.
          </p>
          <p>
            <strong>3.2 Termination for Convenience</strong>. Either Party may terminate this Agreement for any reason
            upon thirty (30) days written notice to the other Party.
          </p>
          <p>
            <strong>3.3 Termination for Cause</strong>. Either Party may terminate this Agreement immediately upon
            written notice if the other Party breaches any material term of this Agreement and fails to cure such breach
            within thirty (30) days after receipt of written notice of the breach.
          </p>
          <p>
            <strong>3.4 Termination for Non-Payment</strong>. Company may terminate this Agreement immediately upon
            written notice if Client fails to pay any amount due under this Agreement within thirty (30) days after
            Company provides written notice of such non-payment.
          </p>
        </section>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">4. Fees and Payment</h2>
          <p>
            <strong>4.1 Subscription Fees</strong>. Client shall pay the subscription fees set forth in Exhibit C.
          </p>
          <p>
            <strong>4.2 Payment Terms</strong>. Subscription fees are due monthly, net 30, and usage fees are due at the
            end of each month.
          </p>
          <p>
            <strong>4.3 Late Payment Penalties</strong>. Standard late payment penalties applicable to SaaS contracts
            shall apply.
          </p>
        </section>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">5. Confidentiality and Data Protection</h2>
          <p>
            <strong>5.1 Confidentiality</strong>.
          </p>
          <p>
            <strong>Definition of Confidential Information</strong>: "Confidential Information" means any non-public
            information disclosed by either Party (the "Disclosing Party") to the other Party (the "Receiving Party")
            that is designated as confidential or that reasonably should be understood to be confidential given the
            nature of the information and the circumstances of disclosure. Confidential Information includes, but is not
            limited to, client data, business plans, technical data, product ideas, customer lists, and proprietary
            software.
          </p>
          <p>
            <strong>Obligations</strong>: The Receiving Party agrees to (i) use the Confidential Information solely for
            the purposes of performing its obligations under this Agreement, (ii) not disclose the Confidential
            Information to any third party without the Disclosing Party's prior written consent, and (iii) protect the
            Confidential Information from unauthorized access, use, or disclosure with the same degree of care it uses
            to protect its own confidential information, but in no event less than reasonable care.
          </p>

          <p>
            <strong>5.2 Data Protection</strong>.
          </p>
          <p>
            <strong>Client Data Ownership</strong>: Client retains all rights, title, and interest in and to any data
            that Client inputs, uploads, or otherwise submits to the Services ("Client Data"). Company acknowledges that
            it has no ownership rights in Client Data.
          </p>
          <p>
            <strong>Data Usage</strong>: Company will use Client Data solely to provide the Services to Client and will
            not disclose Client Data to any third party except as necessary to provide the Services or as required by
            law.
          </p>
          <p>
            <strong>Data Security</strong>: Company shall implement and maintain appropriate technical and
            organizational measures to protect Client Data against unauthorized or unlawful processing and against
            accidental loss, destruction, damage, theft, alteration, or disclosure. These measures shall include, at a
            minimum, encryption of Client Data in transit and at rest, access controls, and regular security audits.
          </p>
          <p>
            <strong>Compliance with Laws</strong>: Company shall comply with all applicable data protection and privacy
            laws, including but not limited to the General Data Protection Regulation (GDPR) and the California Consumer
            Privacy Act (CCPA), in its processing of Client Data.
          </p>
          <p>
            <strong>Data Breach Notification</strong>: In the event of a data breach that affects Client Data, Company
            shall promptly notify Client and provide all reasonable assistance to mitigate the effects of the breach and
            prevent further unauthorized access or disclosure.
          </p>

          <p>
            <strong>5.3 Exclusions</strong>.
          </p>
          <p>
            Confidential Information does not include information that (i) is or becomes generally known to the public
            without breach of any obligation owed to the Disclosing Party, (ii) was known to the Receiving Party prior
            to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party,
            (iii) is received from a third party without breach of any obligation owed to the Disclosing Party, or (iv)
            was independently developed by the Receiving Party without use of or reference to the Disclosing Party's
            Confidential Information.
          </p>

          <p>
            <strong>5.4 Return or Destruction of Confidential Information</strong>.
          </p>
          <p>
            Upon termination of this Agreement, the Receiving Party shall, upon request, return or destroy all
            Confidential Information of the Disclosing Party in its possession, provided that the Receiving Party may
            retain copies of Confidential Information as required by law or to comply with its internal document
            retention policies, subject to the confidentiality obligations herein.
          </p>
        </section>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">6. Warranties and Disclaimers</h2>
          <p>
            <strong>6.1 Warranties</strong>. Company warrants that the Services will be performed in a professional and
            workmanlike manner consistent with industry standards.
          </p>
          <p>
            <strong>6.2 Disclaimers</strong>. Except as expressly provided herein, the Services and Deliverables are
            provided "as is," and Company disclaims all other warranties, whether express, implied, statutory, or
            otherwise.
          </p>
        </section>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">7. Limitation of Liability</h2>
          <p>
            <strong>7.1 Liability Cap</strong>. Company's liability for any claim arising out of or relating to this
            Agreement shall not exceed the amount paid by Client to Company in the twelve (12) months preceding the
            claim.
          </p>
          <p>
            <strong>7.2 Exclusions</strong>. In no event shall either Party be liable for any indirect, incidental,
            special, consequential, or punitive damages, whether or not foreseeable and whether or not such Party has
            been advised of the possibility of such damages.
          </p>
        </section>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">8. Indemnification</h2>
          <p>
            <strong>8.1 Indemnification</strong>. Each Party shall indemnify, defend, and hold harmless the other Party
            from any third-party claims arising out of or related to the indemnifying Party's breach of this Agreement,
            gross negligence, or willful misconduct.
          </p>
        </section>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">9. Governing Law and Dispute Resolution</h2>
          <p>
            <strong>9.1 Governing Law</strong>. This Agreement shall be governed by and construed in accordance with the
            laws of the State of Delaware.
          </p>
          <p>
            <strong>9.2 Dispute Resolution</strong>. Any disputes arising out of or related to this Agreement shall be
            resolved through binding arbitration in accordance with the rules of the American Arbitration Association.
          </p>
        </section>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">10. Miscellaneous</h2>
          <p>
            <strong>10.1 Amendments</strong>. Any amendment to this Agreement must be in writing and signed by both
            Parties.
          </p>
          <p>
            <strong>10.2 Assignment</strong>. Client may not assign this Agreement without the prior written consent of
            Company.
          </p>
          <p>
            <strong>10.3 Notices</strong>. All notices under this Agreement shall be in writing and sent to the
            addresses set forth above or to such other address as a Party may specify in writing.
          </p>
        </section>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">Exhibit A: Description of Services and Deliverables</h2>
          <p>
            <strong>Services</strong>: Artifact is the "long term memory for LLMs," offering a graph-based RAG
            (Retrieval Augmented Generation) solution. It enables developers or teams to create an account and use our
            fully hosted solution to create a new graph, ingest documents and information, and then query this graph to
            get a response.
          </p>
          <p>
            <strong>Deliverables</strong>: Access to the Artifact cloud console to manage their graphs, see usage, and
            test the graph with test queries, as well as access to our Python client that is well-documented in order to
            create and manage graphs, ingest documents, query the graph, and more. This client is API-based, and users
            are able to create API keys within our online console portal.
          </p>
        </section>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">Exhibit B: Service Levels (For Enterprise Clients)</h2>
          <p>
            <strong>Uptime</strong>: Company will use commercially reasonable efforts to ensure an uptime of 99.9%.
          </p>
        </section>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">Exhibit C: Fees</h2>
          <p>
            <strong>Starter Plan</strong>: Free (capped at 3 graphs, 1000 write units, 1000 read units, 10MB storage
            max.)
          </p>
          <p>
            <strong>Standard Plan</strong>: $299/month flat fee, and thereafter $0.0008/GB/hour, $1.11/1k write units,
            $0.28/1k read units.
          </p>
          <p>
            <strong>Enterprise Plan</strong>: Pricing available upon request, double the price for storage, writes, and
            reads from the Standard Plan.
          </p>
        </section>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">Primary Contact for Legal Matters</h2>
          <p>Ozone Technologies Inc.</p>
          <p>1475 Folsom St, Ste #100</p>
          <p>San Francisco, CA 94105, USA</p>
          <p>Email: contact@ozone.pro</p>
          <p>Phone: 7075048442</p>
        </section>
      </div>
    </div>
  );
};
