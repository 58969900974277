export enum RoutePaths {
  Home = '/:tab?/:graphId?/:mode?',
  LoginSuccessful = '/login_successful',
  Login = '/login',
  SignUp = '/signup',
  LoadingPage = '/loading',
  AcceptInvitePage = '/accept_invite',
  CheckoutSuccessPage = '/checkout_success',
  MSA = '/msa',
  Privacy = '/privacy-policy',
}
