import { OperationLog } from '../../types/operationLog';
import { fetchApi } from '../fetchApi';

export async function getGraphOperationLogs(graphId: string): Promise<Array<OperationLog>> {
  try {
    const data = await fetchApi('get_operation_logs', { graph_uuid: graphId });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
