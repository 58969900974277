import { useSelector } from 'react-redux';
import { Toast } from '../Toast/Toast';
import { selectNotificationsToasts } from '../../../stores/GlobalNotifications/slice/selectors';

export function ToastContainer() {
  const toasts = useSelector(selectNotificationsToasts);

  return (
    <div className="fixed bottom-1.5 right-1.5 space-y-1">
      {toasts.map((toast, index) => (
        <div key={index}>
          <Toast id={toast.id} message={toast.message} icon={toast.icon} />
        </div>
      ))}
    </div>
  );
}
