import { UsageReport } from '../../types/usage';
import { fetchApi } from '../fetchApi';

export async function getUsage(organizationId: string, startDate: Date, endDate: Date): Promise<UsageReport> {
  try {
    const data = await fetchApi('get_usage', {
      organization_uuid: organizationId,
      start_time: startDate.getTime(),
      end_time: endDate.getTime(),
    });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
