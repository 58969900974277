import { TabView } from '../../components/TabView/TabView';
import { TabHeader } from '../../components/TabHeader/TabHeader';
import { TabContent } from '../../components/TabContent/TabContent';
import { ValueView } from '../../../../components/ValueView/ValueView';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAppOrganizationId,
  selectAppOrganizationName,
  selectAppPlan,
} from '../../../../../stores/GlobalApp/slice/selectors';
import { CopyIndicator } from '../../../../components/CopyIndicator/CopyIndicator';
import { EditIndicator } from '../../../../components/EditIndicator/EditIndicator';
import { Button } from '../../../../components/Button/Button';
import { ButtonType } from '../../../../components/Button/Button.types';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { updateOrganization } from '../../../../../helpers/api/updateOrganization';
import { fetchOrCreateOrganization } from '../../../../../stores/GlobalApp/thunks/fetchOrCreateOrganization';
import { capitalizeWords } from '../../../../../utils/capitalizeWords';

export function SettingsTab() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orgName = useSelector(selectAppOrganizationName);
  const orgId = useSelector(selectAppOrganizationId);
  const planName = useSelector(selectAppPlan);

  const onUpdateName = useCallback(
    (name: string) => {
      if (!orgId) return;
      updateOrganization(orgId, { name: name }).then(() => {
        dispatch(fetchOrCreateOrganization() as any);
      });
    },
    [dispatch, orgId],
  );

  return (
    <TabView>
      <TabHeader>
        <div className="font-semibold">Settings</div>
      </TabHeader>
      <TabContent>
        <ValueView
          value={capitalizeWords((planName ?? 'Starter').toLowerCase())}
          label="Plan"
          action={
            <div className="mt-[-8px] h-3">
              <Button label="Manage plans" type={ButtonType.PRIMARY} onClick={() => navigate('/plans')} />
            </div>
          }
        />
        <ValueView
          value={orgName ?? ''}
          label="Organization name"
          action={<EditIndicator value={orgName ?? ''} setValue={onUpdateName} />}
        />
        <ValueView value={orgId ?? ''} label="Organization ID" action={<CopyIndicator value={orgId ?? ''} />} />
      </TabContent>
    </TabView>
  );
}
