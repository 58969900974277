import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../types/redux/RootState';
import { selectAppOrganizationId } from '../../GlobalApp/slice/selectors';
import { getGraphs } from '../../../helpers/api/getGraphs';
import { graphsActions } from '../slice';
import { LoadingStatus } from '../../GlobalUser/slice/types';

export function fetchGraphs() {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const state = getState();
    const organizationId = selectAppOrganizationId(state);
    if (!organizationId) return;
    dispatch(graphsActions.updateLoadingStatus(LoadingStatus.LOADING));
    getGraphs(organizationId)
      .then(graphs => {
        dispatch(graphsActions.setGraphs(graphs));
        dispatch(graphsActions.updateLoadingStatus(LoadingStatus.SUCCEEDED));
      })
      .catch(() => {
        dispatch(graphsActions.updateLoadingStatus(LoadingStatus.FAILED));
      });
  };
}
