import { User } from '../../types/user';
import { fetchApi } from '../fetchApi';
import { logout } from '../../stores/GlobalUser/hooks/useLogoutUser';

export async function getUser(userId: string): Promise<User> {
  try {
    return await fetchApi('get_user', { user_uuid: userId });
  } catch (error) {
    console.error('Error:', error);
    try {
      await logout();
    } catch (signOutError) {
      console.error('Error signing out:', signOutError);
    }
    // signOut should redirect, but just in case, reject with error, maybe throw a toast?
    return Promise.reject(new Error('Failed to fetch user data and redirected to login.'));
  }
}
