import { RootState } from '../../../types/redux/RootState';
import { initialState } from './index';
import { createSelector } from '@reduxjs/toolkit';

const selectSlice = (state: RootState) => state?.user || initialState;

export const selectUser = createSelector([selectSlice], state => state.user);

export const selectCognitoUserStatus = createSelector([selectSlice], state => state.cognitoStatus);
export const selectUserStatus = createSelector([selectSlice], state => state.userStatus);

export const selectUserId = createSelector([selectSlice], state => state.cognitoId);
export const selectCognitoUserName = createSelector([selectSlice], state => state.cognitoName);

export const selectCognitoUserPicture = createSelector([selectSlice], state => state.cognitoPicture);

export const selectCognitoUserJwtToken = createSelector([selectSlice], state => state.cognitoJwtToken);

export const selectUserLoggedIn = createSelector([selectSlice], state => state.cognitoId !== '');
