import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../types/redux/RootState';
import { selectUserId } from '../../GlobalUser/slice/selectors';
import { selectAppStatus } from '../slice/selectors';
import { LoadingStatus } from '../../GlobalUser/slice/types';
import { appActions } from '../slice';
import { getOrganizationsByUserId } from '../../../helpers/api/getOrganizationsByUserId';

export function fetchOrganizations() {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const state = getState();
    const userId = selectUserId(state);
    const appStatus = selectAppStatus(state);
    if (!userId) return;
    if (appStatus === LoadingStatus.LOADING) return;
    dispatch(appActions.updateStatus(LoadingStatus.LOADING));
    getOrganizationsByUserId(userId).then(organizations => {
      if (organizations && organizations.length >= 1) {
        dispatch(appActions.updateOrganizations(organizations));
        dispatch(appActions.updateStatus(LoadingStatus.SUCCEEDED));
      }
    });
  };
}
