import { GraphEdge } from '../../types/graph';
import { fetchApi } from '../fetchApi';

export async function getGraphEdges(graphId: string): Promise<Array<GraphEdge>> {
  try {
    const data = await fetchApi('get_graph_edges', {
      graph_uuid: graphId,
    });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
