import { TeamMember } from './teamMember';
import { ApiKey } from './apiKey';
import { OrganizationMetadata } from './organizationMetadata';

export enum SubscriptionPlan {
  STARTER = 'STARTER',
  STANDARD = 'STANDARD',
  ENTERPRISE = 'ENTERPRISE',
}

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  PAUSED = 'PAUSED',
  PAST_DUE = 'PAST_DUE',
}

export interface Subscription {
  subscription_id: string;
  plan: SubscriptionPlan;
  status: SubscriptionStatus;
  start_date: string;
}

export interface Organization {
  uuid: string;
  name: string;
  owner_uuid: string;
  members: TeamMember[];
  api_keys: ApiKey[];
  subscription: Subscription;
  created_at: Date;
  metadata: OrganizationMetadata;
}
