import { signInWithRedirect, fetchAuthSession } from '@aws-amplify/auth';

export function signUpWithGoogle(redirectUrl?: string) {
  if (redirectUrl) {
    signInWithRedirect({
      provider: 'Google',
      customState: redirectUrl,
    });
  } else {
    signInWithRedirect({
      provider: 'Google',
    });
  }
}

export const getJwtToken = async (): Promise<any> => {
  try {
    const authSession = await fetchAuthSession();
    return authSession.tokens?.idToken?.toString();
  } catch (error) {
    console.error('Error getting JWT token:', error);
    return null;
  }
};
