import { useDispatch, useSelector } from 'react-redux';
import { selectAllOrganizations, selectAppOrganizationName } from '../../../../../stores/GlobalApp/slice/selectors';
import { useCallback, useState } from 'react';
import { Organization } from '../../../../../types/organization';
import { appActions } from '../../../../../stores/GlobalApp/slice';
import { createAndSelectOrganization } from '../../../../../stores/GlobalApp/thunks/createOrganization';
import { ExpandIcon, ShrinkIcon } from '../../../../components/Icons';
import { graphsActions } from '../../../../../stores/GlobalGraphs/slice';

export function OrganizationSection() {
  const dispatch = useDispatch();
  const organizationName = useSelector(selectAppOrganizationName);
  const allOrganizations = useSelector(selectAllOrganizations);
  const [isExpanded, setIsExpanded] = useState(false);

  const onSelectOrganization = useCallback(
    (orgId: string) => {
      dispatch(appActions.updateActiveOrganizationId(orgId));
      localStorage.setItem('activeOrganizationId', orgId);
      setIsExpanded(false);
      dispatch(graphsActions.setGraphs([]));
    },
    [dispatch],
  );

  const onCreateOrganization = useCallback(() => {
    dispatch(createAndSelectOrganization() as any);
    dispatch(graphsActions.setGraphs([]));
    setIsExpanded(false);
  }, [dispatch]);

  return (
    <div className="relative p-1">
      <div
        className="flex cursor-pointer items-center justify-between text-[16px] font-semibold"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {organizationName}
        <span className="material-icons-outlined">
          {!isExpanded && <ExpandIcon />}
          {isExpanded && <ShrinkIcon />}
        </span>
      </div>
      {isExpanded && (
        <div className="mt-2 flex flex-col">
          {allOrganizations &&
            allOrganizations.map((org: Organization) => (
              <div
                key={org.uuid}
                className="cursor-pointer rounded-[8px] px-1.5 py-1 font-medium hover:bg-gray-100 active:bg-gray-200"
                onClick={() => onSelectOrganization(org.uuid)}
              >
                {org.name}
              </div>
            ))}
          <button className="mt-2 rounded bg-blue-500 p-1 text-white hover:bg-blue-600" onClick={onCreateOrganization}>
            Create organization
          </button>
        </div>
      )}
    </div>
  );
}
