import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { acceptInvite } from '../../../helpers/api/acceptInvite';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from '../../../stores/GlobalUser/slice/selectors';
import { IS_LOCALHOST } from '../../../helpers/envHelper';
import { fetchApi } from '../../../helpers/fetchApi';
import { getUserAttributes } from '../../../helpers/getUserAttributes';

function getErrorFromUrl({ queryParams }: { queryParams: any }) {
  const error = queryParams.get('error');
  const errorDescription = queryParams.get('error_description');

  if (error || errorDescription) {
    return { error, errorDescription };
  }

  return null;
}

export function LoginSuccessPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const fetchAttributes = async () => {
      try {
        const userAttributes = await getUserAttributes();

        // Now that we have the user attributes, we tell the api to reconcile the user
        console.info(`Reconciling user in API`, userAttributes);
        fetchApi('reconcile_user', { userAttributes }).then(_ => {
          handleInviteOrRedirect(userAttributes?.uuid);
        });
      } catch (error) {
        console.error('Error fetching user attributes:', error);
        navigate('/');
      }
    };

    const handleInviteOrRedirect = (userAttrsId: string | undefined) => {
      const token = queryParams.get('token');
      const uid = userAttrsId || userId;

      if (token) {
        // Call the backend to validate and accept the invite
        acceptInvite(uid, token)
          .then(() => {
            navigate('/');
          })
          .catch(() => {
            alert('Error while accepting invite (user-id / token)');
            navigate('/');
          });
      } else {
        // Accept any invites
        acceptInvite(uid)
          .then(() => {
            navigate('/');
          })
          .catch(e => {
            console.error(`Error while accepting invite (user-id only) \n${e.message}`, e);
            navigate('/');
          });
      }
    };

    // login might not have been successful, despite name of this react component
    const errInfo = getErrorFromUrl({ queryParams: queryParams });
    if (errInfo) {
      console.error('Login failed:', errInfo);
      alert('Failed to authorize user');
      navigate('/');
      return;
    }
    console.info('Login Success called with params:', queryParams);

    // alternate flow when logging in from localhost in dev mode
    if (process.env.NODE_ENV === 'development' && IS_LOCALHOST) {
      fetchAttributes().then(() => console.info('Successfully processed user log in'));
    } else {
      if (!userId) {
        console.warn('User ID not yet defined');
      } else {
        handleInviteOrRedirect(undefined);
      }
    }
  }, [dispatch, location.search, navigate, userId]);

  return (
    <div className="flex size-full flex-col items-center justify-center font-medium text-white">
      Login successful! Redirecting...
    </div>
  );
}
