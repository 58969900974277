import { fetchApi } from '../fetchApi';

export async function getInTouch(userId: string, organizationId: string): Promise<void> {
  try {
    const data = await fetchApi('get_in_touch', { user_uuid: userId, organization_uuid: organizationId });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
