import { fetchApi } from '../fetchApi';

export async function getCheckoutUrl(userId: string): Promise<string> {
  try {
    const data = await fetchApi('get_checkout_url', { user_uuid: userId });
    return data.checkout_url;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
