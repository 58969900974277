import { Organization } from '../../types/organization';
import { fetchApi } from '../fetchApi';

/** Creates new organization and returns organization id **/
export async function createOrganization(userId: string): Promise<Organization> {
  try {
    const data = await fetchApi('create_organization', { user_uuid: userId });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
