import { fetchApi } from '../fetchApi';
import { Graph } from '../../types/graph';
import { PartialDeep } from 'type-fest';

export async function updateGraph(graphId: string, graphUpdate: PartialDeep<Graph>): Promise<string> {
  try {
    const data = await fetchApi('update_graph', {
      graph_uuid: graphId,
      graph: graphUpdate,
    });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
