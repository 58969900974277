import { DataSource } from '../../../types/datasource';
import { useCallback, useEffect, useState } from 'react';
import { Modal } from '../Modal/Modal';
import { Button } from '../Button/Button';
import { ButtonType } from '../Button/Button.types';
import { useSelector } from 'react-redux';
import { selectAppOrganizationId } from '../../../stores/GlobalApp/slice/selectors';
import { DataConnector, DataConnectorSyncFrequency } from '../../../types/dataconnector';
import { createDataConnector } from '../../../helpers/api/createDataConnector';
import { useParams } from 'react-router-dom';
import { listDataSources } from '../../../helpers/api/listDataSources';

export function DataConnectorModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { graphId } = useParams();
  const [step, setStep] = useState(1);
  const [selectedSourceId, setSelectedSourceId] = useState<string | null>(null);
  const [selectedFrequency, setSelectedFrequency] = useState<DataConnectorSyncFrequency | null>(null);
  const organizationId = useSelector(selectAppOrganizationId);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSources, setDataSources] = useState<null | Array<DataSource>>();

  useEffect(() => {
    if (!organizationId) return;
    listDataSources(organizationId).then(res => setDataSources(res));
  }, [organizationId]);

  const handleReset = useCallback(() => {
    setStep(1);
    setSelectedSourceId(null);
    setSelectedFrequency(null);
    setDataSources(null);
  }, []);

  const handleSubmit = useCallback(() => {
    if (!organizationId || !selectedSourceId || !selectedFrequency || !graphId) return;
    setLoading(true);

    const dataConnector: Partial<DataConnector> = {
      data_source_uuid: selectedSourceId,
      graph_uuid: graphId,
      sync_frequency: selectedFrequency,
      organization_uuid: organizationId,
    };

    createDataConnector(organizationId, dataConnector).then(() => {
      setLoading(false);
      handleReset();
      onClose();
    });
  }, [organizationId, selectedSourceId, selectedFrequency, graphId, handleReset, onClose]);

  const handleNext = () => {
    if (step === 1 && selectedSourceId) {
      setStep(2);
    } else if (step === 2 && selectedFrequency) {
      setStep(3);
    }
  };

  const handleBack = () => {
    if (step === 2) {
      setStep(1);
    } else if (step === 3) {
      setStep(2);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      {step === 1 ? (
        <div>
          <h2 className="mb-4 text-xl font-semibold">Select from available data sources</h2>
          <ul className="flex flex-col gap-y-2">
            {dataSources &&
              dataSources.map(dataSource => (
                <li key={dataSource.uuid}>
                  <Button
                    label={dataSource.name}
                    onClick={() => setSelectedSourceId(dataSource.uuid)}
                    type={selectedSourceId === dataSource.uuid ? ButtonType.PRIMARY : ButtonType.SECONDARY}
                  />
                </li>
              ))}
            {!dataSources && <div>Loading available data sources...</div>}
          </ul>
          <div className="mt-4 flex justify-end gap-x-2">
            <Button label="Next" onClick={handleNext} type={ButtonType.PRIMARY} disabled={!selectedSourceId} />
          </div>
        </div>
      ) : (
        <div>
          <h2 className="mb-4 text-xl font-semibold">Select Sync Frequency</h2>
          <ul className="flex flex-col gap-y-2">
            {[
              DataConnectorSyncFrequency.ONE_TIME,
              DataConnectorSyncFrequency.DAILY,
              DataConnectorSyncFrequency.HOURLY,
              DataConnectorSyncFrequency.QUARTER_HOURLY,
            ].map(frequency => (
              <li key={frequency}>
                <Button
                  label={frequency}
                  onClick={() => setSelectedFrequency(frequency)}
                  type={selectedFrequency === frequency ? ButtonType.PRIMARY : ButtonType.SECONDARY}
                />
              </li>
            ))}
          </ul>
          <div className="mt-4 flex justify-between">
            <Button label="Back" onClick={handleBack} type={ButtonType.SECONDARY} />
            <Button label="Submit" onClick={handleSubmit} type={ButtonType.PRIMARY} disabled={!selectedFrequency} />
          </div>
        </div>
      )}
    </Modal>
  );
}
