import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCognitoUserStatus } from '../../GlobalUser/slice/selectors';
import { LoadingStatus } from '../../GlobalUser/slice/types';
import { selectAppStatus } from '../slice/selectors';
import { appActions } from '../slice';
import { fetchOrCreateOrganization } from '../thunks/fetchOrCreateOrganization';

export function useFetchInitial() {
  const dispatch = useDispatch();
  const userStatus = useSelector(selectCognitoUserStatus);
  const appStatus = useSelector(selectAppStatus);

  useEffect(() => {
    if (userStatus !== LoadingStatus.SUCCEEDED) return;
    if (appStatus === LoadingStatus.SUCCEEDED) return;
    dispatch(fetchOrCreateOrganization() as any);

    // Select initial organization id
    const locallyStoredActiveOrganizationId = localStorage.getItem('activeOrganizationId');
    if (locallyStoredActiveOrganizationId) {
      dispatch(appActions.updateActiveOrganizationId(locallyStoredActiveOrganizationId));
    }
  }, [appStatus, dispatch, userStatus]);
}
