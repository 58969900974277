import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoadingStatus } from '../../../stores/GlobalUser/slice/types';
import { useEffect } from 'react';
import { Account } from '../../components/Account/Account';
import { MenuTab } from '../../components/MenuTab/MenuTab';
import { CloudConnectIcon, GraphIcon, LockIcon, PersonIcon, SettingsIcon, UsageIcon } from '../../components/Icons';
import { GraphsTab } from './tabs/GraphsTab/GraphsTab';
import { TeamTab } from './tabs/TeamTab/TeamTab';
import { SettingsTab } from './tabs/SettingsTab/SettingsTab';
import { ApiKeysTab } from './tabs/ApiKeysTab/ApiKeysTab';
import { CreateGraphsTab } from './tabs/CreateGraphTab/CreateGraphsTab';
import { selectAppPlan } from '../../../stores/GlobalApp/slice/selectors';
import { PlansTab } from './tabs/PlansTab/PlansTab';
import { PlanIndicator } from '../../components/PlanIndicator/PlanIndicator';
import { useFetchUser } from '../../../stores/GlobalApp/hooks/useFetchUser';
import { selectCognitoUserStatus, selectUserLoggedIn } from '../../../stores/GlobalUser/slice/selectors';
import { useFetchInitial } from '../../../stores/GlobalApp/hooks/useFetchInitial';
import { UsageTab } from './tabs/UsageTab/UsageTab';
import { OrganizationSection } from './components/OrganizationSection/OrganizationSection';
import { SubscriptionPlan } from '../../../types/organization';
import { CommunityIndicator } from '../../components/CommunityIndicator/CommunityIndicator';

import logo from '../../assets/logoBlack.png';
import { DataSourcesTab } from './tabs/DataSourcesTab/DataSourcesTab';

enum Tab {
  GRAPHS = 'graphs',
  DATA_SOURCES = 'datasources',
  API_KEYS = 'apikeys',
  USAGE = 'usage',
  TEAM = 'team',
  SETTINGS = 'settings',
  CREATE_GRAPH = 'creategraph',
  PLANS = 'plans',
}

export interface TabSwitchProps {
  tab: Tab;
}

export function TabSwitch(props: TabSwitchProps) {
  switch (props.tab) {
    case Tab.GRAPHS:
      return <GraphsTab />;
    case Tab.DATA_SOURCES:
      return <DataSourcesTab />;
    case Tab.TEAM:
      return <TeamTab />;
    case Tab.SETTINGS:
      return <SettingsTab />;
    case Tab.API_KEYS:
      return <ApiKeysTab />;
    case Tab.CREATE_GRAPH:
      return <CreateGraphsTab />;
    case Tab.PLANS:
      return <PlansTab />;
    case Tab.USAGE:
      return <UsageTab />;
  }
}

export function HomePage() {
  const { tab } = useParams();
  useFetchUser();
  useFetchInitial();
  const navigate = useNavigate();
  const userStatus = useSelector(selectCognitoUserStatus);
  const isUserLoggedIn = useSelector(selectUserLoggedIn);
  const isLoading = userStatus === LoadingStatus.LOADING || userStatus === LoadingStatus.IDLE;
  const currentPlan = useSelector(selectAppPlan);
  const showPlanIndicator = currentPlan === SubscriptionPlan.STARTER;

  useEffect(() => {
    if (isLoading) {
      //navigate('/loading');
      return;
    }
    if (!isLoading && !isUserLoggedIn) {
      navigate('/login');
      return;
    }
    if (!tab) {
      navigate('/graphs');
      return;
    }
  }, [isLoading, isUserLoggedIn, navigate, tab]);

  const handleTabClick = (tab: Tab) => {
    navigate(`/${tab as string}`);
  };

  return (
    <div className="_HomePage flex size-full h-screen flex-col gap-1.5 overflow-hidden">
      <div className="_Header flex h-9 min-h-9 w-full flex-row items-center justify-between bg-white px-3">
        <div className="_Name cursor-pointer text-[20px] font-semibold text-black" onClick={() => navigate('/')}>
          <img src={logo} alt={'logo'} className="h-4" />
        </div>

        <div className="_RightSide flex flex-row gap-x-3">
          <a className="cursor-pointer text-[15px] font-medium text-black" href="https://docs.useartifact.ai">
            Docs
          </a>
          <a className="text-[15px] font-medium text-black" href="mailto:max@ozone.pro">
            Support
          </a>
          <Account />
        </div>
      </div>
      <div className="_Wrapper flex h-full grow flex-row gap-x-1.5 p-1.5 pt-0" style={{ height: 'calc(100vh - 84px)' }}>
        <div className="_LeftMenu flex h-full w-40 flex-col justify-between gap-y-2 rounded-[12px] bg-white p-2">
          <div className="flex w-full flex-col gap-y-2">
            <OrganizationSection />
            <div className="_Menu flex flex-col gap-y-0.5">
              <MenuTab
                icon={<GraphIcon />}
                label={'Knowledge Graphs'}
                isActive={tab === Tab.GRAPHS}
                onClick={() => handleTabClick(Tab.GRAPHS)}
              />
              <MenuTab
                icon={<CloudConnectIcon />}
                label={'Data Sources (beta)'}
                isActive={tab === Tab.DATA_SOURCES}
                onClick={() => handleTabClick(Tab.DATA_SOURCES)}
              />
              <MenuTab
                icon={<LockIcon />}
                label={'API Keys'}
                isActive={tab === Tab.API_KEYS}
                onClick={() => handleTabClick(Tab.API_KEYS)}
              />
              <MenuTab
                icon={<UsageIcon />}
                label={'Usage'}
                isActive={tab === Tab.USAGE}
                onClick={() => handleTabClick(Tab.USAGE)}
              />
              <MenuTab
                icon={<PersonIcon />}
                label={'Team'}
                isActive={tab === Tab.TEAM}
                onClick={() => handleTabClick(Tab.TEAM)}
              />
              <MenuTab
                icon={<SettingsIcon />}
                label={'Settings'}
                isActive={tab === Tab.SETTINGS}
                onClick={() => handleTabClick(Tab.SETTINGS)}
              />
            </div>
          </div>
          <div className="flex w-full flex-col justify-end gap-y-2">
            {showPlanIndicator && <PlanIndicator />}
            <CommunityIndicator />
          </div>
        </div>
        <div className="_Viewport flex w-full flex-col rounded-[12px] bg-white">
          <TabSwitch tab={tab as Tab} />
        </div>
      </div>
    </div>
  );
}
