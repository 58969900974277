import { useSelector } from 'react-redux';
import { selectUserLoggedIn, selectCognitoUserStatus } from '../../../stores/GlobalUser/slice/selectors';
import { LoadingStatus } from '../../../stores/GlobalUser/slice/types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logosmall from './assets/logosmall.png';

export function LoadingPage() {
  const navigate = useNavigate();
  const userStatus = useSelector(selectCognitoUserStatus);
  const isUserLoggedIn = useSelector(selectUserLoggedIn);
  const isLoading = userStatus === LoadingStatus.LOADING || userStatus === LoadingStatus.IDLE;

  useEffect(() => {
    if (!isLoading && !isUserLoggedIn) {
      navigate('/login');
      return;
    }
    if (!isLoading && isUserLoggedIn) {
      //navigate('/');
      return;
    }
  }, [isLoading, isUserLoggedIn, navigate]);

  return (
    <div className="_LoginPage flex size-full flex-col items-center justify-center bg-black">
      {isLoading && (
        <div className="h-4">
          <img src={logosmall} alt="" className="h-4 animate-spin" />
        </div>
      )}
    </div>
  );
}
