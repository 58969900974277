import { DataSourceStatus } from '../../../../../types/datasource';

interface Props {
  status: DataSourceStatus;
}

export function DataSourceStatusView(props: Props) {
  let statusText = '';
  let statusColor = '';
  switch (props.status) {
    case DataSourceStatus.HEALTHY:
      statusText = 'Healthy';
      statusColor = 'green';
      break;
    case DataSourceStatus.FAILURE:
      statusText = 'Failure connecting';
      statusColor = 'red';
      break;
  }

  const cls = [DataSourceStatus.FAILURE].includes(props.status) ? 'animate-pulse' : '';

  return (
    <div className="flex flex-row items-center text-[11px] font-semibold text-ozoneV2-grey-300">
      <span
        style={{
          height: '10px',
          width: '10px',
          backgroundColor: statusColor,
          borderRadius: '50%',
          display: 'inline-block',
          marginRight: '6px',
        }}
        className={cls}
      ></span>
      {statusText}
    </div>
  );
}
