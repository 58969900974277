import { fetchApi } from '../fetchApi';

export async function deleteGraph(graphId: string): Promise<void> {
  try {
    const data = await fetchApi('delete_graph', { graph_uuid: graphId });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
