import { ConversationHistory } from '../../../../../../../../../types/conversationHistory';
import { MessageBubble } from '../../../../../../../../components/MessageBubble/MessageBubble';
import { useEffect, useRef } from 'react';
import { Message } from '../../../../../../../../../types/message';

function swapSystemUserPairs(messages: Message[]): Message[] {
  const swappedMessages = [...messages];
  for (let i = 0; i < swappedMessages.length - 1; i++) {
    if (swappedMessages[i].role === 'system' && swappedMessages[i + 1].role === 'user') {
      const temp = swappedMessages[i];
      swappedMessages[i] = swappedMessages[i + 1];
      swappedMessages[i + 1] = temp;
      i++; // Skip next pair to avoid re-swapping
    }
  }
  return swappedMessages;
}

interface Props {
  conversationHistory: ConversationHistory;
}

export function ChatHistory(props: Props) {
  const numberOfMessages = useRef<number>(-1);
  const endOfMessagesRef = useRef<null | HTMLDivElement>(null);

  // Scroll to the bottom every time messages update
  useEffect(() => {
    if (!endOfMessagesRef.current) return;

    const newMessagesCount = props.conversationHistory.messages.length;

    if (numberOfMessages.current !== newMessagesCount) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
      numberOfMessages.current = newMessagesCount;
    }
  }, [props.conversationHistory.messages]);

  /** TODO: We are filtering out assistant messages for now **/
  return (
    <div className="flex h-20 grow overflow-y-auto px-1.5">
      <div className="flex w-full flex-col">
        {swapSystemUserPairs(
          props.conversationHistory.messages.filter(
            message => message.content.length !== 0 && message.role !== 'assistant',
          ),
        ).map((message, index) => (
          <div
            key={index}
            style={{ marginBottom: index === props.conversationHistory.messages.length - 1 ? '8px' : '0' }}
          >
            <MessageBubble content={message.content} role={message.role} key={message.content} />
          </div>
        ))}
        <div ref={endOfMessagesRef} />
      </div>
    </div>
  );
}
