import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

interface Props {
  code: string;
  language: string;
}

export function CodeBlock(props: Props) {
  return (
    <SyntaxHighlighter
      language={props.language}
      style={vscDarkPlus}
      customStyle={{ padding: '20px 24px', borderRadius: '6px', width: '100%' }}
    >
      {props.code}
    </SyntaxHighlighter>
  );
}
