import React, { useEffect, useRef, useState } from 'react';

interface Props {
  labels: Array<string>;
  active: string;
  onChange: (label: string) => void;
}

export function HorizontalMenu(props: Props) {
  const [indicatorStyles, setIndicatorStyles] = useState({ width: 0, left: 0 });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const activeItem = containerRef.current?.querySelector('.active-item') as HTMLElement;

    if (activeItem) {
      setIndicatorStyles({
        width: activeItem.offsetWidth,
        left: activeItem.offsetLeft,
      });
    }
  }, [props.active]);

  return (
    <div className="relative flex grow-0 flex-row gap-x-1.5" ref={containerRef}>
      {props.labels.map(label => (
        <div
          key={label}
          className={`duration-50 relative mb-0.5 cursor-pointer rounded-[8px] px-1 py-0.5 text-[14px] font-[600] transition-all ease-in-out ${
            label === props.active
              ? 'active-item text-ozoneV2-brand-blue-primary hover:bg-ozoneV2-brand-blue-primary/10'
              : 'text-ozoneV2-grey-600 hover:bg-ozoneV2-grey-900/20'
          }`}
          onClick={() => props.onChange(label)}
        >
          <span>{label}</span>
        </div>
      ))}

      {/* Animated bottom border */}
      <div
        className="absolute bottom-0 h-[2px] bg-ozoneV2-brand-blue-primary transition-all duration-150 ease-in-out"
        style={{
          width: `${indicatorStyles.width}px`,
          left: `${indicatorStyles.left}px`,
        }}
      />
    </div>
  );
}
