import { TabHeader } from '../../components/TabHeader/TabHeader';
import { TabContent } from '../../components/TabContent/TabContent';
import { TabView } from '../../components/TabView/TabView';
import { Input } from '../../../../components/Input/Input';
import { useCallback, useState } from 'react';
import { Button } from '../../../../components/Button/Button';
import { ButtonType } from '../../../../components/Button/Button.types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GraphIndexInterval } from '../../../../../types/graph';
import { createGraph } from '../../../../../helpers/api/createGraph';
import { selectAppOrganizationId } from '../../../../../stores/GlobalApp/slice/selectors';
import { fetchGraphs } from '../../../../../stores/GlobalGraphs/thunks/fetchGraphs';

export function CreateGraphsTab() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const orgId = useSelector(selectAppOrganizationId);

  const validateName = (name: string) => {
    const isValid = /^[a-z0-9-_]+$/.test(name);
    if (!isValid) {
      setErrorMessage(
        'Graph name must contain only lowercase letters, numbers, dashes, and underscores, with no spaces.',
      );
    } else {
      setErrorMessage(null);
    }
    return isValid;
  };

  const onCancel = useCallback(() => {
    navigate('/graphs');
  }, [navigate]);

  const onSubmit = useCallback(() => {
    if (!orgId || !validateName(name)) return;

    createGraph(orgId, name, GraphIndexInterval.IMMEDIATE).then(() => {
      dispatch(fetchGraphs() as any);
    });

    navigate('/graphs');
  }, [dispatch, name, navigate, orgId]);

  const handleNameChange = useCallback((value: string) => {
    setName(value);
    validateName(value);
  }, []);

  return (
    <TabView>
      <TabHeader>
        <div className="text-[16px] font-semibold">Create a new knowledge graph</div>
      </TabHeader>
      <TabContent>
        <div className="_Wrapper flex w-40 flex-col gap-y-1.5">
          <Input value={name} onChange={handleNameChange} placeholder="Graph name" initiallyFocused={true} />

          {errorMessage && <div className="text-sm text-red-500">{errorMessage}</div>}

          <div className="flex flex-row gap-x-1 self-end">
            <Button label="Cancel" onClick={() => onCancel()} type={ButtonType.SECONDARY} />
            <Button
              label="New graph"
              onClick={() => onSubmit()}
              type={ButtonType.PRIMARY}
              disabled={name.length === 0 || !!errorMessage}
            />
          </div>
        </div>
      </TabContent>
    </TabView>
  );
}
