import { TabHeader } from '../../components/TabHeader/TabHeader';
import { TabContent } from '../../components/TabContent/TabContent';
import { TabView } from '../../components/TabView/TabView';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAppOrganizationId,
  selectAppOrganizationMetadata,
} from '../../../../../stores/GlobalApp/slice/selectors';
import { getUsage } from '../../../../../helpers/api/getUsage';
import { UsageReport } from '../../../../../types/usage';
import { LoadingCircle } from '../../../../components/LoadingCircle/LoadingCircle';

const getCurrentYear = () => {
  const date = new Date();
  const year = date.toLocaleString('default', { year: 'numeric' });
  return year;
};

const getCurrentMonth = () => {
  const date = new Date();
  const month = date.toLocaleString('default', { month: 'long' });
  return month;
};

function getMonthBoundaries(monthYear: string) {
  // Parse the string into a Date object, assuming the format "Month Year"
  const startDate = new Date(monthYear);

  // Set the date to the first day of the month
  startDate.setDate(1);
  startDate.setHours(0, 0, 0, 0); // Reset time to the start of the day

  // Create a new Date object for the end date by copying the start date
  const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

  // Set the end time to the last moment of the day
  endDate.setHours(23, 59, 59, 999);

  return { startDate, endDate };
}

export function UsageTab() {
  const [currentMonth, setCurrentMonth] = useState(getCurrentMonth());
  const [currentYear, setCurrentYear] = useState(getCurrentYear());
  const organizationId = useSelector(selectAppOrganizationId);
  const [usageReport, setUsageReport] = useState<UsageReport | null>(null);
  const isLoading = usageReport === null;
  const total_document_storage_kb = useSelector(selectAppOrganizationMetadata)?.documents_storage_size_kb ?? 0;
  const total_document_storage_cost = (total_document_storage_kb / 1_000_000) * 0.62;

  useEffect(() => {
    if (!organizationId) return;
    if (usageReport) return;
    const monthBoundaries = getMonthBoundaries(currentMonth + ' ' + currentYear);
    getUsage(organizationId, monthBoundaries.startDate, monthBoundaries.endDate).then(ur => setUsageReport(ur));
  }, [currentMonth, currentYear, organizationId, usageReport]);

  return (
    <TabView>
      <TabHeader>
        <div className="font-semibold">Usage</div>
      </TabHeader>
      <TabContent>
        {isLoading && (
          <div className="flex size-full items-center justify-center">
            <LoadingCircle />
          </div>
        )}
        {!isLoading && (
          <div className="flex select-text flex-col gap-y-2">
            <div className="flex flex-col gap-y-2">Showing usage for month: {currentMonth}</div>
            <div className="flex flex-col gap-y-0.5">
              <h2 className="text-[20px] font-bold">Storage usage</h2>
              <div>Number of documents: {usageReport?.total_document_count}</div>
              <div>Storage used: {total_document_storage_kb} kB</div>
              <div>Cost of storage: ${total_document_storage_cost} / month</div>
            </div>
            <div className="flex flex-col gap-y-0.5">
              <h2 className="text-[20px] font-bold">Unit usage</h2>
              <div>Write units: {usageReport?.total_write_units}</div>
              <div>Read units: {usageReport?.total_read_units}</div>
            </div>
            <div className="flex flex-col gap-y-0.5">
              <h2 className="text-[20px] font-bold">Index operations</h2>
              <div>Number of index operations: {usageReport?.total_index_count}</div>
              <div>Number of index tokens: {usageReport?.total_index_tokens}</div>
              <div>Cost of indexing: $ {usageReport?.total_index_cost}</div>
            </div>
            <div className="flex flex-col gap-y-0.5">
              <h2 className="text-[20px] font-bold">Query operations</h2>
              <div>Number of query operations: {usageReport?.total_query_count}</div>
              <div>Number of query tokens: {usageReport?.total_query_tokens}</div>
              <div>Cost of querying: $ {usageReport?.total_query_cost}</div>
            </div>
          </div>
        )}
      </TabContent>
    </TabView>
  );
}
