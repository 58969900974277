import { DocumentMetadata } from '../../types/documentMetadata';
import { fetchApi } from '../fetchApi';

export async function getDocumentsMeta(graphId: string): Promise<DocumentMetadata[]> {
  try {
    const data = await fetchApi('get_documents_meta', {
      graph_uuid: graphId,
    });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
