import { Route, Routes } from 'react-router-dom';
import { RoutePaths } from './constants';
import { HomePage } from '../../pages/HomePage';
import { LoginSuccessPage } from '../../pages/LoginSuccessPage/LoginSuccessPage';
import { LoginPage } from '../../pages/LoginPage/LoginPage';
import { LoadingPage } from '../../pages/LoadingPage/LoadingPage';
import { AcceptInvitePage } from '../../pages/AcceptInvitePage/AcceptInvitePage';
import { CheckoutSuccessPage } from '../../pages/CheckoutSuccessPage/CheckoutSuccessPage';
import { MSAPage } from '../../pages/MSAPage/MSAPage';
import { PrivacyPolicyPage } from '../../pages/PrivacyPolicyPage/PrivacyPolicyPage';

export function RoutesSwitch() {
  return (
    <Routes>
      <Route path={RoutePaths.Home} element={<HomePage />} />
      <Route path={RoutePaths.Login} element={<LoginPage />} />
      <Route path={RoutePaths.SignUp} element={<LoginPage isSignup />} />
      <Route path={RoutePaths.AcceptInvitePage} element={<AcceptInvitePage />} />
      <Route path={RoutePaths.CheckoutSuccessPage} element={<CheckoutSuccessPage />} />
      <Route path={RoutePaths.LoginSuccessful} element={<LoginSuccessPage />} />
      <Route path={RoutePaths.LoadingPage} element={<LoadingPage />} />
      <Route path={RoutePaths.MSA} element={<MSAPage />} />
      <Route path={RoutePaths.Privacy} element={<PrivacyPolicyPage />} />
      <Route path="*" element={<div>Site not found!</div>} />
    </Routes>
  );
}
