import { Button } from '../Button/Button';
import { Input } from '../Input/Input';
import { ButtonType } from '../Button/Button.types';
import { useEffect } from 'react';

interface InputModalProps {
  open: boolean;
  onClose: () => void;
  value: string;
  setValue: (val: string) => void;
  onSave: () => void;
  placeholder?: string;
  buttonLabel?: string;
}

export function InputModal({ open, onClose, value, setValue, onSave, placeholder, buttonLabel }: InputModalProps) {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [open, onClose]);

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  if (!open) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50"
      onClick={handleBackdropClick}
    >
      <div className="flex w-[400px] flex-col gap-y-2 rounded-[8px] bg-white p-5">
        <Input
          value={value}
          onChange={v => setValue(v)}
          placeholder={placeholder ? placeholder : 'Edit Value'}
          initiallyFocused={true}
        />
        <div className="flex justify-end gap-x-1">
          <Button onClick={onClose} label="Cancel" type={ButtonType.SECONDARY} />
          <Button onClick={onSave} label={buttonLabel ? buttonLabel : 'Save'} type={ButtonType.PRIMARY} />
        </div>
      </div>
    </div>
  );
}
