import { fetchApi } from '../fetchApi';

export async function subscribeToPlan(userId: string, organizationId: string, sessionId: string): Promise<void> {
  try {
    const data = await fetchApi('subscribe', {
      user_uuid: userId,
      organization_uuid: organizationId,
      session_id: sessionId,
    });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
