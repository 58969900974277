import { TabHeader } from '../../components/TabHeader/TabHeader';
import { TabContent } from '../../components/TabContent/TabContent';
import { TabView } from '../../components/TabView/TabView';
import { ButtonType } from '../../../../components/Button/Button.types';
import { Button } from '../../../../components/Button/Button';
import { useCallback, useState } from 'react';
import { InputModal } from '../../../../components/InputModal/InputModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppOrganizationId, selectAppTeamMembers } from '../../../../../stores/GlobalApp/slice/selectors';
import { TeamMemberRole, TeamMemberStatus } from '../../../../../types/teamMember';
import { inviteTeamMember } from '../../../../../helpers/api/inviteTeamMember';
import { capitalizeWords } from '../../../../../utils/capitalizeWords';
import { fetchOrCreateOrganization } from '../../../../../stores/GlobalApp/thunks/fetchOrCreateOrganization';

export function TeamTab() {
  const dispatch = useDispatch();
  const teamMembers = useSelector(selectAppTeamMembers);
  const [modalOpen, setModalOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState<string>('');
  const organizationId = useSelector(selectAppOrganizationId);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleSubmit = useCallback(() => {
    if (!organizationId) return;
    inviteTeamMember(organizationId, inviteEmail, TeamMemberRole.ADMIN).then(() => {
      // Refresh team members
      dispatch(fetchOrCreateOrganization() as any);
    });
    handleClose();
  }, [dispatch, inviteEmail, organizationId]);

  return (
    <TabView>
      <TabHeader>
        <div className="font-semibold">Team</div>
        <Button label="Invite member" type={ButtonType.PRIMARY} onClick={handleOpen} />
      </TabHeader>
      <TabContent>
        <div className="container mx-auto p-4">
          <div className="overflow-hidden rounded-lg border-x border-t bg-white">
            <div className="flex border-b bg-gray-100 px-4 py-2 font-medium">
              <div className="flex-1">Name</div>
              <div className="flex-1">Email</div>
              <div className="flex-1">Status</div>
              <div className="flex-1">Role</div>
            </div>
            {teamMembers &&
              teamMembers.map((member, index) => (
                <div key={index} className="flex border-b px-4 py-2 hover:bg-gray-50">
                  <div className="flex-1">{member.name}</div>
                  <div className="flex-1">{member.email}</div>
                  <div className="flex-1">
                    <span
                      className={`inline-block rounded-[8px] px-1 py-0.5 text-sm font-semibold ${
                        member.status === TeamMemberStatus.ACCEPTED
                          ? 'bg-green-200 text-green-800'
                          : 'bg-yellow-200 text-yellow-800'
                      }`}
                    >
                      {capitalizeWords(member.status.toLowerCase())}
                    </span>
                  </div>
                  <div className="flex-1">{capitalizeWords(member.role.toLowerCase())}</div>
                </div>
              ))}
          </div>
        </div>
        <InputModal
          open={modalOpen}
          onClose={handleClose}
          value={inviteEmail}
          setValue={setInviteEmail}
          onSave={handleSubmit}
          placeholder="User email"
          buttonLabel="Invite user"
        />
      </TabContent>
    </TabView>
  );
}
