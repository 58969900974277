import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { subscribeToPlan } from '../../../helpers/api/subscribeToPlan';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../../stores/GlobalUser/slice/selectors';
import { selectAppOrganizationId } from '../../../stores/GlobalApp/slice/selectors';
import { useFetchUser } from '../../../stores/GlobalApp/hooks/useFetchUser';
import { useFetchInitial } from '../../../stores/GlobalApp/hooks/useFetchInitial';
import { LoadingCircle } from '../../components/LoadingCircle/LoadingCircle';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function CheckoutSuccessPage() {
  const query = useQuery();
  useFetchUser();
  useFetchInitial();
  const sessionId = query.get('session_id');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const userId = useSelector(selectUserId);
  const organizationId = useSelector(selectAppOrganizationId);

  useEffect(() => {
    if (!organizationId) return;
    if (sessionId) {
      subscribeToPlan(userId, organizationId, sessionId)
        .then(() => {
          setLoading(false);
          setSuccess(true);
        })
        .catch(() => {
          setLoading(false);
          setError('Error verifying checkout session');
        })
        .finally(() => {
          setLoading(false);
          navigate('/');
        });
    } else {
      setError('No session ID provided.');
      setLoading(false);
    }
  }, [navigate, organizationId, sessionId, userId]);

  if (loading) {
    return <LoadingCircle />;
  }

  if (error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error}</p>
        <button onClick={() => navigate('/')}>Go to Home</button>
      </div>
    );
  }

  if (success) {
    return (
      <div>
        <h1>Checkout Successful!</h1>
        <p>Your subscription has been activated successfully.</p>
        <button onClick={() => navigate('/dashboard')}>Go to Dashboard</button>
      </div>
    );
  }

  return null;
}
