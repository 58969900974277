import { ButtonType } from './Button.types';
import { MouseEventHandler, ReactNode } from 'react';

interface Props {
  label: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  type: ButtonType;
  icon?: ReactNode;
  disabled?: boolean;
}

export function Button({ label, onClick, type, disabled, icon }: Props) {
  const baseClasses =
    'px-[10px] py-[6px] rounded-[6px] font-medium text-[13px] focus:outline-none duration-150 flex flex-row gap-x-1.5 items-center justify-center';
  const primaryClasses = 'bg-blue-600 text-[#F0F3FF] hover:bg-blue-700 active:bg-blue-800';
  const secondaryClasses = 'bg-gray-100 text-[#4e4e4e] hover:bg-gray-200 active:bg-gray-300 shadow shadow-[#0000]/12';
  const disabledClasses = 'bg-gray-300 text-gray-500 cursor-not-allowed';

  const buttonClasses = `${baseClasses} ${disabled ? disabledClasses : type === ButtonType.PRIMARY ? primaryClasses : secondaryClasses}`;

  return (
    <button className={buttonClasses} onClick={disabled ? undefined : onClick} disabled={disabled}>
      {icon}
      {label}
    </button>
  );
}
