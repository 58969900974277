import { DocumentMetadata, DocumentUploadStatus } from '../../../../../types/documentMetadata';

const statusToString = (status: DocumentUploadStatus) => {
  switch (status) {
    case DocumentUploadStatus.Pending:
      return 'Pending';
    case DocumentUploadStatus.Failed:
      return 'Failed';
    case DocumentUploadStatus.Uploading:
      return 'Uploading';
    case DocumentUploadStatus.Uploaded:
      return 'Uploaded';
    default:
      break;
  }
};

interface DocumentRowProps {
  documentMetadata: DocumentMetadata;
}

export function DocumentRow(props: DocumentRowProps) {
  const { documentMetadata } = props;

  return (
    <div className="flex w-full flex-row border-b border-gray-200 bg-white p-2">
      <div className="flex-1 text-left font-medium">{documentMetadata.file_name}</div>
      <div className="flex-1 text-left">{statusToString(documentMetadata.status)}</div>
      <div className="flex-1 text-right">{(documentMetadata.size_bytes / 1024).toFixed(2)} KB</div>
    </div>
  );
}
