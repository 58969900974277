export enum DataConnectorSyncStatus {
  IDLE = 'IDLE',
  SYNCING = 'SYNCING',
}

export enum DataConnectorSyncFrequency {
  ONE_TIME = 'ONE_TIME',
  HOURLY = 'HOURLY',
  QUARTER_HOURLY = 'QUARTER_HOURLY',
  DAILY = 'DAILY',
}

export interface DataConnector {
  uuid: string;
  organization_uuid: string;
  graph_uuid: string;
  data_source_uuid: string;
  created_at: Date;
  sync_frequency: DataConnectorSyncFrequency;
  last_sync_time: Date;
  sync_status: DataConnectorSyncStatus;
}
