import { GraphNode } from '../../types/graph';
import { fetchApi } from '../fetchApi';

export async function getGraphNodes(graphId: string): Promise<Array<GraphNode>> {
  try {
    const data = await fetchApi('get_graph_nodes', {
      graph_uuid: graphId,
    });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
