import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useState } from 'react';

interface Props {
  role: 'assistant' | 'system' | 'user';
  content: string;
}

export function MessageBubble({ role, content }: Props) {
  const isUser = role === 'user';
  const isSystem = role === 'system';
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (isSystem) {
    return (
      <div className={`mb-1 flex justify-start`}>
        <div
          className={`relative max-w-[700px] select-text rounded-[12px] bg-[#01579B] px-2 py-1.5 font-medium text-white ${isCollapsed ? 'h-10 overflow-hidden' : 'h-auto'}`}
          onClick={handleToggleCollapse}
          style={{ cursor: 'pointer' }}
        >
          <div className="mb-1 text-[11px] font-light">RAG RESPONSE</div>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              h1: ({ node, ...props }) => <h1 className="markdown-heading text-[20px] font-semibold" {...props} />,
              p: ({ node, ...props }) => <p {...props} style={{ whiteSpace: 'pre-wrap' }} />,
            }}
            className="whitespace-pre-wrap"
          >
            {content}
          </ReactMarkdown>
          {isCollapsed && (
            <span className="absolute right-1.5 top-1.5 text-[11px] font-light text-white">CLICK TO EXPAND</span>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-1`}>
      <div
        className={`select-text rounded-[12px] px-2 py-1.5 font-medium ${isUser ? 'bg-blue-500 text-white' : 'bg-blue-500 text-white'} max-w-[700px]`}
      >
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            h1: ({ node, ...props }) => <h1 className="markdown-heading text-[20px] font-semibold" {...props} />,
            p: ({ node, ...props }) => <p {...props} style={{ whiteSpace: 'pre-wrap' }} />,
          }}
          className="whitespace-pre-wrap"
        >
          {content}
        </ReactMarkdown>
      </div>
    </div>
  );
}
