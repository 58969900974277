import { PencilIcon } from '../Icons';
import { InputModal } from '../InputModal/InputModal';
import { useState } from 'react';

interface Props {
  value: string;
  setValue: (val: string) => void;
}

export function EditIndicator({ value, setValue }: Props) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSave = () => {
    setValue(inputValue);
    handleClose();
  };

  return (
    <div className="cursor-pointer">
      <div onClick={handleOpen} className="size-2.5">
        <PencilIcon className="size-full text-ozoneV2-grey-700" />
      </div>
      <InputModal open={open} onClose={handleClose} value={inputValue} setValue={setInputValue} onSave={handleSave} />
    </div>
  );
}
