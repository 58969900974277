import { Button } from '../../../../components/Button/Button';
import { ButtonType } from '../../../../components/Button/Button.types';
import { useNavigate, useParams } from 'react-router-dom';
import { TabHeader } from '../../components/TabHeader/TabHeader';
import { TabContent } from '../../components/TabContent/TabContent';
import { AddIcon, ArrowRightIcon, GraphIcon } from '../../../../components/Icons';
import { TabView } from '../../components/TabView/TabView';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllGraphs, selectGraphsLoadingStatus } from '../../../../../stores/GlobalGraphs/slice/selectors';
import { GraphCard } from '../../../../components/GraphCard/GraphCard';
import { useCallback, useState } from 'react';
import { graphsActions } from '../../../../../stores/GlobalGraphs/slice';
import { useFetchGraphs } from '../../../../../stores/GlobalGraphs/hooks/useFetchGraphs';
import { GraphDetail } from './components/GraphDetail/GraphDetail';
import { deleteGraph } from '../../../../../helpers/api/deleteGraph';
import { downloadXMLFile } from '../../../../../utils/downloadFile';
import { getGraphFile } from '../../../../../helpers/api/getGraphFile';
import { DataConnectorModal } from '../../../../components/DataConnectorModal/DataConnectorModal';
import { LoadingStatus } from '../../../../../stores/GlobalUser/slice/types';
import { LoadingCircle } from '../../../../components/LoadingCircle/LoadingCircle';
import { selectAppPlan } from '../../../../../stores/GlobalApp/slice/selectors';
import { SubscriptionPlan } from '../../../../../types/organization';

export enum GraphTab {
  Overview = 'Overview',
  Chat = 'Chat',
  Visualization = 'Visualization',
  DataConnectors = 'Data Connectors',
  UploadData = 'Upload Data',
  Logs = 'Logs',
}

export function GraphsTab() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { graphId, mode } = useParams();
  const graphs = useSelector(selectAllGraphs);
  const activeGraph = graphs.find(g => g.uuid === graphId);
  const showAllGraphs = !activeGraph;
  const showGraphDetail = !!activeGraph && mode === undefined;
  const graphsEmpty = graphs?.length === 0;
  const graphsLoading = [LoadingStatus.LOADING, LoadingStatus.IDLE].includes(useSelector(selectGraphsLoadingStatus));
  const [activeTab, setActiveTab] = useState<string>(GraphTab.Overview);
  const [dataConnectorModalOpen, setDataConnectorModalOpen] = useState(false);
  const currentPlan = useSelector(selectAppPlan);

  useFetchGraphs();

  const onDeleteGraph = useCallback(() => {
    if (!activeGraph) return;
    if (!graphId) return;
    dispatch(graphsActions.deleteGraph(activeGraph?.uuid));
    deleteGraph(graphId).then(() => navigate('/graphs'));
  }, [activeGraph, dispatch, graphId, navigate]);

  const onAddConnector = useCallback(() => {
    setDataConnectorModalOpen(true);
  }, []);

  const onExportGraph = useCallback(() => {
    if (!activeGraph?.summarized_graphml_url) return;
    if (!graphId) return;
    getGraphFile(graphId).then(xmlStr => downloadXMLFile(xmlStr, activeGraph.name + '.graphml'));
  }, [activeGraph?.name, activeGraph?.summarized_graphml_url, graphId]);

  const handleDataConnectorModalClose = useCallback(() => {
    setDataConnectorModalOpen(false);
  }, []);

  return (
    <TabView>
      <TabHeader>
        <div className="flex flex-col gap-x-1 text-[20px] font-semibold">
          {activeGraph ? (
            <div className="flex flex-col gap-y-0.5">
              <div className="flex flex-row items-center gap-x-1 text-[12px]" onClick={() => navigate('/graphs')}>
                <div className="cursor-pointer text-ozoneV2-brand-blue-primary">Knowledge Graphs</div>{' '}
                <ArrowRightIcon className="inline size-1 text-ozoneV2-grey-600" />
              </div>
              <div>{activeGraph.name}</div>
            </div>
          ) : (
            <div>Knowledge Graphs</div>
          )}
        </div>

        <div className="flex h-4 flex-row gap-x-1.5">
          {!activeGraph && (
            <Button
              icon={<AddIcon className="size-1.5" />}
              label="New Graph"
              type={ButtonType.PRIMARY}
              onClick={() => navigate('/creategraph')}
            />
          )}
          {activeGraph && (
            <>
              {(activeTab === GraphTab.Chat ||
                activeTab === GraphTab.Logs ||
                activeTab === GraphTab.UploadData ||
                activeTab === GraphTab.Overview) && (
                <Button label="Delete graph" type={ButtonType.SECONDARY} onClick={onDeleteGraph} />
              )}
              {activeTab === GraphTab.DataConnectors && (
                <Button
                  icon={<AddIcon className="size-1.5" />}
                  label="Add connector"
                  type={ButtonType.PRIMARY}
                  onClick={onAddConnector}
                  disabled={currentPlan !== SubscriptionPlan.ENTERPRISE}
                />
              )}
              {activeTab === GraphTab.Visualization && (
                <Button label="Export graph" type={ButtonType.PRIMARY} onClick={onExportGraph} />
              )}
            </>
          )}
        </div>
      </TabHeader>
      <TabContent>
        {showAllGraphs && (
          <div className="relative size-full">
            {!graphsLoading && graphsEmpty && (
              <div className="flex h-full flex-col items-center justify-center gap-y-4">
                <div className="flex flex-col items-center justify-center gap-y-0.5">
                  <div className="text-[32px] font-bold">No knowledge graphs yet!</div>
                  <div className="font-medium text-ozoneV2-grey-700">Get started by creating your first graph.</div>
                </div>
                <GraphIcon className="size-10 text-ozoneV2-grey-900" />
              </div>
            )}
            {graphsLoading && graphsEmpty && (
              <div className="flex h-full flex-col items-center justify-center gap-y-4">
                <div className="flex flex-col items-center justify-center gap-y-0.5">
                  <LoadingCircle />
                </div>
              </div>
            )}
            {!graphsEmpty && (
              <div className="absolute inset-y-0 size-full overflow-y-scroll">
                <div className="flex w-full flex-col gap-y-1">
                  {!graphsEmpty && graphs.map(graph => <GraphCard key={graph.uuid} graph={graph} />)}
                </div>
              </div>
            )}
          </div>
        )}

        {showGraphDetail && <GraphDetail activeTab={activeTab} setActiveTab={setActiveTab} />}
      </TabContent>
      <DataConnectorModal open={dataConnectorModalOpen} onClose={handleDataConnectorModalClose} />
    </TabView>
  );
}
