import { GlobalApp } from './GlobalApp';
import { GlobalUser } from './GlobalUser';
import { GlobalGraphs } from './GlobalGraphs';
import { GlobalNotifications } from './GlobalNotifications';

export function GlobalStores() {
  return (
    <>
      <GlobalApp />
      <GlobalUser />
      <GlobalGraphs />
      <GlobalNotifications />
    </>
  );
}
