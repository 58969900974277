import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { LoadingStatus } from '../slice/types';
import { selectUserId, selectUserStatus } from '../slice/selectors';
import { loadUser } from '../thunks/loadUser';

export function useLoadUser() {
  const dispatch = useDispatch();
  const cognitoUserId = useSelector(selectUserId);
  const userStatus = useSelector(selectUserStatus);

  useEffect(() => {
    if (!cognitoUserId || cognitoUserId === '') return;
    if (userStatus !== LoadingStatus.IDLE) return;
    dispatch(loadUser() as any);
  }, [dispatch, userStatus, cognitoUserId]);
}
