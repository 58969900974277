import { useSelector } from 'react-redux';
import { selectUserLoggedIn, selectCognitoUserStatus } from '../../../stores/GlobalUser/slice/selectors';
import { LoadingStatus } from '../../../stores/GlobalUser/slice/types';
import { LoginCard } from './components/LoginCard/LoginCard';

import logo from '../../assets/logoWhite.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingCircle } from '../../components/LoadingCircle/LoadingCircle';

interface Props {
  isSignup?: boolean;
}

export function LoginPage(props: Props) {
  const navigate = useNavigate();
  const userStatus = useSelector(selectCognitoUserStatus);
  const isUserLoggedIn = useSelector(selectUserLoggedIn);
  const isLoading = userStatus === LoadingStatus.LOADING || userStatus === LoadingStatus.IDLE;

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate('/');
      return;
    }
  }, [isUserLoggedIn, navigate]);

  return (
    <div className="_LoginPage flex size-full">
      {isLoading && <LoadingCircle />}
      {!isLoading && props.isSignup && (
        <div className="_SignUpWrapper flex size-full flex-row">
          <div className="flex h-full w-3/5 flex-col gap-y-4 bg-black p-20">
            <img src={logo} alt={'logo'} className="w-15" />
            <div className="text-[55px] font-bold leading-[70px] text-white">
              Build the most reliable AI apps using our RAG graphs. Start for free today.
            </div>
          </div>
          <div className="flex h-full grow flex-row items-center justify-center bg-[#F2F4F7]">
            <LoginCard isSignup />
          </div>
        </div>
      )}
      {!isLoading && !props.isSignup && (
        <div className="_LoginWrapper flex size-full flex-row items-center justify-center bg-[#F2F4F7]">
          {!isUserLoggedIn && <LoginCard isSignup={false} />}
        </div>
      )}
    </div>
  );
}
