import { fetchApi } from '../fetchApi';

export async function changePlan(userId: string, organizationId: string, plan: string): Promise<void> {
  try {
    const data = await fetchApi('change_plan', { user_uuid: userId, organization_uuid: organizationId, plan: plan });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
