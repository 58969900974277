import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer } from '../../../utils/redux/redux-injectors';
import { GraphsState } from './types';
import { LoadingStatus } from '../../GlobalUser/slice/types';
import { Graph } from '../../../types/graph';

export const initialState: GraphsState = { status: LoadingStatus.IDLE, graphs: [] };

const slice = createSlice({
  name: 'graphs',
  initialState,
  reducers: {
    setGraphs(state, action: PayloadAction<Array<Graph>>) {
      state.graphs = action.payload;
    },
    upsertGraph(state, action: PayloadAction<Graph>) {
      const index = state.graphs.findIndex(graph => graph.uuid === action.payload.uuid);
      if (index !== -1) {
        state.graphs[index] = action.payload;
      } else {
        state.graphs.push(action.payload);
      }
    },
    deleteGraph(state, action: PayloadAction<string>) {
      state.graphs = state.graphs.filter(graph => graph.uuid !== action.payload);
    },
    updateLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.status = action.payload;
    },
  },
});

export const { actions: graphsActions } = slice;

export const useGraphsState = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
