import { useEffect, useRef, useState } from 'react';
import { LoadingCircle } from '../../../../../../../../components/LoadingCircle/LoadingCircle';

interface Props {
  onSubmit: (text: string) => void;
  isLoading: boolean;
}

export function PromptBar(props: Props) {
  const [prompt, setPrompt] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      const handleInput = () => {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      };

      textarea.addEventListener('input', handleInput);
      return () => textarea.removeEventListener('input', handleInput);
    }
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevents the default action of inserting a newline
      if (prompt.trim()) {
        props.onSubmit(prompt);
        setPrompt('');
        setTimeout(() => {
          // Use setTimeout to delay the resizing
          const textarea = textareaRef.current;
          if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
          }
        }, 0); // Timeout of 0 ms is enough to push to the end of the call stack
      }
    }
  };

  return (
    <div className="_SearchBar broder-[#FAFAFA] flex w-full shrink-0 items-center rounded-[12px] border bg-[#F2F2F2] p-1.5 px-2.5">
      <textarea
        ref={textareaRef}
        className="grow cursor-text resize-none bg-transparent font-medium text-ozoneV2-grey-500 outline-none placeholder:text-gray-400"
        style={{ caretColor: '#2763FF' }}
        placeholder="Ask questions about the data in your knowledge base..."
        autoFocus
        value={prompt}
        onChange={event => setPrompt(event.target.value)}
        onKeyDown={handleKeyDown}
        rows={1}
      />
      <div
        className="ml-2 flex cursor-pointer self-end font-semibold text-ozoneV2-grey-500"
        onClick={() => {
          if (props.isLoading) return;
          props.onSubmit(prompt);
          setPrompt('');
          textareaRef.current?.focus();
        }}
      >
        {!props.isLoading ? 'Submit' : <LoadingCircle />}
      </div>
    </div>
  );
}
