import { fetchApi } from '../fetchApi';

export async function uploadFile(
  organizationId: string,
  graphName: string,
  file: File,
  metadata: Record<string, string> = {},
  onUpdate?: (progress: number) => void, // Update callback
): Promise<string> {
  try {
    // Step 1: Request the presigned URL from your API endpoint
    const presignResponse = await fetchApi('presign', {
      graph_name: graphName,
      file_name: file.name,
      metadata: metadata,
      content_type: file.type,
    });

    if (!presignResponse || !presignResponse.message || presignResponse.message !== 'Success') {
      throw new Error('Failed to get presigned URL');
    }

    const presignedUrl = presignResponse.presigned_url;

    // Step 2: Notify about file upload starting
    onUpdate?.(0);

    // Step 3: Use XMLHttpRequest to upload file with progress tracking
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open('PUT', presignedUrl, true);

      xhr.upload.onprogress = function (event) {
        if (event.lengthComputable && onUpdate) {
          const percentComplete = (event.loaded / event.total) * 100;
          onUpdate(percentComplete); // Update progress
        }
      };

      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          onUpdate?.(100); // Notify about successful upload
          resolve('File uploaded successfully');
        } else {
          onUpdate?.(-1); // Notify about failure
          reject(new Error('Failed to upload file to S3'));
        }
      };

      xhr.onerror = function () {
        onUpdate?.(-1); // Notify about error
        reject(new Error('Error during upload'));
      };

      xhr.setRequestHeader('Content-Type', file.type);

      xhr.send(file); // Send the file
    });
  } catch (error: any) {
    // Notify about error
    onUpdate?.(-1);
    console.error('Error during upload:', error);
    throw error;
  }
}
