import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../types/redux/RootState';
import { selectUserId } from '../../GlobalUser/slice/selectors';
import { getUser } from '../../../helpers/api/getUser';
import { userActions } from '../../GlobalUser/slice';

export function fetchUser() {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const state = getState();
    const userId = selectUserId(state);
    if (!userId) return;
    getUser(userId).then(user => {
      dispatch(userActions.updateUser(user));
    });
  };
}
