import { PromptBar } from './components/PromptBar/PromptBar';
import { ChatHistory } from './components/ChatHistory/ChatHistory';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { submitPrompt } from '../../../../../../../helpers/api/submitPrompt';
import {
  ConversationHistory,
  generateDefaultConversationHistory,
} from '../../../../../../../types/conversationHistory';
import { getConversationHistory } from '../../../../../../../helpers/api/getConversationHistory';
import { Message } from '../../../../../../../types/message';
import { Dropdown } from '../../../../../../components/Dropdown/Dropdown';

const SEARCH_TYPE_OPTIONS = {
  auto: 'Auto Search',
  local: 'Local Search',
  global: 'Global Search',
};

function findSearchTypeKey(label: string): string | undefined {
  const entry = Object.entries(SEARCH_TYPE_OPTIONS).find(([key, value]) => value === label);
  return entry ? entry[0] : undefined;
}

export function GraphChatInterface() {
  const { graphId } = useParams();
  const numberOfMessages = useRef<number>(-1);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const prevConversationHistory = useRef<ConversationHistory | null>(null);
  const [conversationHistory, setConversationHistory] = useState<ConversationHistory>(
    generateDefaultConversationHistory(),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [searchType, setSearchType] = useState<string>(SEARCH_TYPE_OPTIONS.auto);

  useEffect(() => {
    numberOfMessages.current = conversationHistory.messages.length;
  }, [conversationHistory.messages.length]);

  const fetchConversationHistory = useCallback(() => {
    if (!graphId) return;
    getConversationHistory(graphId).then(ch => {
      if (!ch || ch?.messages.length <= numberOfMessages.current) return;
      setConversationHistory(ch);
    });
  }, [graphId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchConversationHistory();
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!graphId || isLoaded) return;
    fetchConversationHistory();
    setIsLoaded(true);
  }, [isLoaded, graphId, fetchConversationHistory]);

  const handleSubmit = useCallback(
    (prompt: string) => {
      if (!graphId) return;
      let tempConversationHistory = Object.assign({}, conversationHistory);
      const newMessage = { role: 'user', content: prompt } as Message;
      prevConversationHistory.current = Object.assign({}, conversationHistory);
      tempConversationHistory.messages = [...tempConversationHistory.messages, newMessage];
      setConversationHistory(tempConversationHistory);
      const searchTypeParam = findSearchTypeKey(searchType);
      submitPrompt(graphId, prompt, searchTypeParam).then(() => {});
    },
    [conversationHistory, graphId, searchType],
  );

  useEffect(() => {
    const prevMsgCount = prevConversationHistory.current?.messages.length ?? 0;
    const currentMsgCount = conversationHistory.messages.length ?? 0;
    const msgCountDelta = currentMsgCount - prevMsgCount;
    const shouldBeLoading = prevConversationHistory.current !== null && currentMsgCount !== 0 && msgCountDelta <= 1;
    setIsLoading(shouldBeLoading);
  }, [conversationHistory]);

  return (
    <div className="relative flex size-full flex-col items-center justify-center">
      <div className="_Wrapper flex h-full w-3/5 grow-0 flex-col justify-between gap-y-0 pb-3">
        <ChatHistory conversationHistory={conversationHistory} />
        <PromptBar onSubmit={handleSubmit} isLoading={isLoading} />
      </div>
      <div className="absolute bottom-4 right-0 w-19">
        <Dropdown
          options={Object.values(SEARCH_TYPE_OPTIONS)}
          defaultLabel={SEARCH_TYPE_OPTIONS.auto}
          onSelect={setSearchType}
          rollUp
        />
      </div>
    </div>
  );
}
