export const PrivacyPolicyPage = () => {
  return (
    <div className="size-full select-text overflow-y-scroll">
      <div className="mx-auto max-w-4xl rounded-lg bg-white p-8 shadow-md">
        <h1 className="mb-1 text-2xl font-bold">Privacy Policy</h1>
        <div className="mb-6 text-sm font-medium">Last updated on August 3, 2024</div>
        <section className="mb-4">
          <h2 className="text-xl font-semibold">Introduction</h2>
          <p>
            Ozone Technologies Inc. ("Company," "we," "our," or "us") is committed to protecting your privacy. This
            Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our
            service, Artifact ("Service"). Please read this Privacy Policy carefully. If you do not agree with the terms
            of this Privacy Policy, please do not use the Service.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold">Information We Collect</h2>
          <h3 className="text-lg font-semibold">1. Personal Information</h3>
          <p>When you register for an account or use the Service, we may collect the following personal information:</p>
          <ul className="list-inside list-disc">
            <li>Name</li>
            <li>Email address</li>
            <li>Google profile picture</li>
            <li>Contact information</li>
          </ul>
          <br />
          <br />

          <h3 className="text-lg font-semibold">2. Usage Data</h3>
          <p>We may collect information about your interactions with the Service, including:</p>
          <ul className="list-inside list-disc">
            <li>Your IP address</li>
            <li>Browser type and version</li>
            <li>Device type</li>
            <li>Pages visited</li>
            <li>Time spent on pages</li>
            <li>Referring URL</li>
            <li>Logs of API requests and responses</li>
          </ul>

          <br />
          <br />

          <h3 className="text-lg font-semibold">3. Client Data</h3>
          <p>
            As part of our Service, you may provide data for ingestion and querying. This data ("Client Data") may
            include documents, files, text, and other information you upload or submit to the Service.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold">How We Use Your Information</h2>
          <h3 className="text-lg font-semibold">1. To Provide and Improve Our Service</h3>
          <p>We use the information we collect to:</p>
          <ul className="list-inside list-disc">
            <li>Operate and maintain the Service</li>
            <li>Process your transactions and manage your subscriptions</li>
            <li>Improve the functionality, performance, and quality of the Service</li>
            <li>Provide customer support</li>
          </ul>
          <br />

          <h3 className="text-lg font-semibold">2. To Communicate with You</h3>
          <p>We may use your personal information to:</p>
          <ul className="list-inside list-disc">
            <li>Send you updates, newsletters, and other communications related to the Service</li>
            <li>Respond to your inquiries and provide support</li>
            <li>Send you administrative information, such as changes to our terms, conditions, and policies</li>
          </ul>
          <br />

          <h3 className="text-lg font-semibold">3. For Security and Compliance</h3>
          <p>We may use your information to:</p>
          <ul className="list-inside list-disc">
            <li>Monitor and analyze usage and trends to improve your experience with the Service</li>
            <li>Detect, prevent, and address technical issues and security breaches</li>
            <li>Ensure compliance with applicable laws and regulations</li>
          </ul>
          <br />
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold">Sharing Your Information</h2>
          <h3 className="text-lg font-semibold">1. With Service Providers</h3>
          <p>
            We may share your information with third-party service providers who perform services on our behalf, such as
            hosting, data analysis, payment processing, and customer support. These service providers are contractually
            obligated to protect your information and use it only for the purposes for which we disclose it to them.
          </p>
          <br />

          <h3 className="text-lg font-semibold">2. For Legal Reasons</h3>
          <p>
            We may disclose your information if required to do so by law or in response to valid requests by public
            authorities (e.g., a court or government agency).
          </p>
          <br />

          <h3 className="text-lg font-semibold">3. Business Transfers</h3>
          <p>
            If we are involved in a merger, acquisition, or asset sale, your information may be transferred. We will
            provide notice before your information is transferred and becomes subject to a different privacy policy.
          </p>
          <br />
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold">Payment Information</h2>
          <p>
            When you make a purchase or subscribe to our Service, we collect payment information such as your credit
            card number, billing address, and other payment-related information. This information is used solely to
            process your transactions. We use third-party payment processors that adhere to the highest standards of
            security to handle your payment information. We do not store your payment information on our servers.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold">Data Security</h2>
          <p>
            We implement and maintain appropriate technical and organizational measures to protect your information
            against unauthorized access, loss, theft, alteration, and disclosure. These measures include encryption of
            data in transit and at rest, access controls, and regular security audits.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold">Data Retention</h2>
          <p>
            We will retain your personal information and Client Data for as long as your account is active or as needed
            to provide you with the Service. We may also retain and use your information to comply with our legal
            obligations, resolve disputes, and enforce our agreements.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold">Your Rights</h2>
          <h3 className="text-lg font-semibold">1. Access and Update</h3>
          <p>
            You have the right to access and update your personal information. You can do this through your account
            settings or by contacting us directly.
          </p>
          <br />

          <h3 className="text-lg font-semibold">2. Delete or Restrict</h3>
          <p>
            You have the right to request the deletion or restriction of your personal information. We will comply with
            your request to the extent required by law.
          </p>
          <br />

          <h3 className="text-lg font-semibold">3. Object</h3>
          <p>
            You have the right to object to the processing of your personal information in certain circumstances. This
            includes the right to object to processing your personal information for direct marketing purposes.
          </p>
          <br />

          <h3 className="text-lg font-semibold">4. Data Portability</h3>
          <p>
            You have the right to request a copy of your personal information in a structured, commonly used, and
            machine-readable format.
          </p>
          <br />
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold">International Transfers</h2>
          <p>
            Your information may be transferred to and maintained on servers located outside your state, province,
            country, or other governmental jurisdiction where the data protection laws may differ from those of your
            jurisdiction. If you are located outside the United States and choose to provide information to us, please
            note that we transfer the data, including personal information, to the United States and process it there.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold">Third-Party Links</h2>
          <p>
            The Service may contain links to third-party websites. We are not responsible for the privacy practices or
            the content of those websites. We encourage you to review the privacy policies of those third parties.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold">Children's Privacy</h2>
          <p>
            Our Service is not intended for use by children under the age of 13. We do not knowingly collect personal
            information from children under 13. If we become aware that we have collected personal information from a
            child under 13, we will take steps to delete such information.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold">Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new
            Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
            Changes to this Privacy Policy are effective when they are posted on this page.
          </p>
        </section>
        <br />

        <section className="mb-4">
          <h2 className="text-xl font-semibold">Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us:</p>
          <p>Ozone Technologies Inc.</p>
          <p>1475 Folsom St, Ste #100</p>
          <p>San Francisco, CA 94105, USA</p>
          <p>Email: contact@ozone.pro</p>
        </section>
      </div>
    </div>
  );
};
