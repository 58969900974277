import { DataSourceType } from '../../../types/datasource';
import { useCallback, useState } from 'react';
import { Modal } from '../Modal/Modal';
import { Button } from '../Button/Button';
import { Input } from '../Input/Input';
import { ButtonType } from '../Button/Button.types';
import { createDataSource } from '../../../helpers/api/createDataSource';
import { useSelector } from 'react-redux';
import { selectAppOrganizationId } from '../../../stores/GlobalApp/slice/selectors';
import { capitalizeWords } from '../../../utils/capitalizeWords';

interface DataSourceConfig {
  [key: string]: string;
}

const dataSourceConfigFields = {
  [DataSourceType.AWS_S3]: ['bucket_name', 'region'],
  [DataSourceType.GOOGLE_DRIVE]: ['folder_id'],
  [DataSourceType.AZURE_BLOB]: ['container_name'],
  [DataSourceType.DROPBOX]: ['folder_path'],
  [DataSourceType.NOTION]: ['database_id'],
  [DataSourceType.GOOGLE_CLOUD]: ['bucket_name', 'project_id'],
};

const dataSourceAuthFields = {
  [DataSourceType.AWS_S3]: ['access_key_id', 'secret_access_key'],
  [DataSourceType.GOOGLE_DRIVE]: ['service_account_json'],
  [DataSourceType.AZURE_BLOB]: ['connection_string'],
  [DataSourceType.DROPBOX]: ['access_token'],
  [DataSourceType.NOTION]: ['api_key'],
  [DataSourceType.GOOGLE_CLOUD]: ['service_account_json'],
};

export function DataSourceModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const [step, setStep] = useState(1);
  const [selectedSourceType, setSelectedSourceType] = useState<DataSourceType | null>(null);
  const [config, setConfig] = useState<DataSourceConfig>({});
  const organizationId = useSelector(selectAppOrganizationId);
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState('');

  const handleReset = useCallback(() => {
    setStep(1);
    setSelectedSourceType(null);
    setConfig({});
    setName('');
  }, []);

  const handleSubmit = useCallback(() => {
    if (!organizationId) return;
    if (!selectedSourceType) return;
    if (name === '') return;

    // TODO: temp hack for testing to allow json to just be pasted
    let cfg: any = null;
    if (selectedSourceType === DataSourceType.GOOGLE_DRIVE) {
      const { folder_id, service_account_json } = config;
      cfg = { folder_id, ...JSON.parse(service_account_json) };
    } else {
      cfg = config;
    }

    setLoading(true);
    createDataSource(organizationId, {
      name: name,
      source_type: selectedSourceType,
      organization_uuid: organizationId,
      configuration: cfg,
    })
      .then(() => {
        setLoading(false);
        handleReset();
        onClose();
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
        const cfg_str = JSON.stringify(cfg, null, 2);
        alert(`Unable to connect to ${selectedSourceType}. Please double check your configuration.\n${cfg_str}`);
      });
  }, [config, handleReset, name, onClose, organizationId, selectedSourceType]);

  const handleNext = () => {
    if (step === 1 && selectedSourceType) {
      setStep(2);
    }
  };

  const handleBack = () => {
    if (step === 2) {
      setStep(1);
    }
  };

  const handleInputChange = (field: string, value: string) => {
    setConfig({ ...config, [field]: value });
  };

  return (
    <Modal open={open} onClose={onClose}>
      {step === 1 ? (
        <div>
          <h2 className="mb-4 text-xl font-semibold">Select Data Source Type</h2>
          <ul className="flex flex-col gap-y-2">
            {Object.values(DataSourceType).map(type => (
              <li key={type}>
                <Button
                  label={type}
                  onClick={() => setSelectedSourceType(type)}
                  type={selectedSourceType === type ? ButtonType.PRIMARY : ButtonType.SECONDARY}
                />
              </li>
            ))}
          </ul>
          <div className="mt-4 flex justify-end gap-x-2">
            <Button label="Next" onClick={handleNext} type={ButtonType.PRIMARY} disabled={!selectedSourceType} />
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-y-0">
          <h2 className="mb-4 text-[16px] font-semibold">Configure data source</h2>
          <div className="flex flex-col gap-y-0">
            <label className="mb-1 text-sm font-medium">Name</label>
            <Input placeholder={`Enter a name`} value={name} onChange={value => setName(value)} />
          </div>
          <h2 className="my-3 text-[16px] font-semibold">Authorization</h2>
          <form className="flex flex-col gap-y-2">
            {selectedSourceType &&
              dataSourceAuthFields[selectedSourceType].map(field => (
                <div key={field} className="flex flex-col gap-y-0">
                  <label className="mb-1 text-sm font-medium">
                    {capitalizeWords(field.replace('_', ' ').toLowerCase())}
                  </label>
                  <Input
                    placeholder={`Enter ${field}`}
                    value={config[field] || ''}
                    onChange={value => handleInputChange(field, value)}
                  />
                </div>
              ))}
          </form>
          <h2 className="my-3 text-[16px] font-semibold">Source</h2>
          <form className="flex flex-col gap-y-2">
            {selectedSourceType &&
              dataSourceConfigFields[selectedSourceType].map(field => (
                <div key={field} className="flex flex-col gap-y-0">
                  <label className="mb-1 text-sm font-medium">
                    {capitalizeWords(field.replace('_', ' ').toLowerCase())}
                  </label>
                  <Input
                    placeholder={`Enter ${field}`}
                    value={config[field] || ''}
                    onChange={value => handleInputChange(field, value)}
                  />
                </div>
              ))}
          </form>
          <div className="mt-4 flex justify-between">
            <Button label="Back" onClick={handleBack} type={ButtonType.SECONDARY} />
            <Button label="Submit" onClick={handleSubmit} type={ButtonType.PRIMARY} disabled={loading} />
          </div>
        </div>
      )}
    </Modal>
  );
}
