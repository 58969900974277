import { fetchApi } from '../fetchApi';
import { DataConnector } from '../../types/dataconnector';

export async function createDataConnector(
  organizationId: string,
  dataConnector: Partial<DataConnector>,
): Promise<DataConnector> {
  try {
    const data = await fetchApi('create_data_connector', {
      organization_uuid: organizationId,
      data_connector: dataConnector,
    });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
