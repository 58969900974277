import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../types/redux/RootState';
import { userActions } from '../slice';
import { LoadingStatus } from '../slice/types';
import { getUser } from '../../../helpers/api/getUser';
import { selectUserId } from '../slice/selectors';

export function loadUser() {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const state = getState();
    const cognitoUserId = selectUserId(state);
    if (!cognitoUserId || cognitoUserId === '') return;
    dispatch(userActions.updateUserLoadingStatus(LoadingStatus.LOADING));
    getUser(cognitoUserId)
      .then(user => {
        if (!user) {
          throw Error('User could not be retrieved!');
        }
        dispatch(userActions.updateUser(user));
      })
      .catch(() => {
        throw Error('User could not be retrieved!');
      });
  };
}
