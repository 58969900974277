import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { Button } from '../Button/Button';
import { ButtonType } from '../Button/Button.types';
import { DataSourceType } from '../../../types/datasource';
import { AWSS3Icon, AzureIcon, GoogleCloudIcon, GoogleDriveIcon, NotionIcon, DropboxIcon } from '../Icons';
import { useSelector } from 'react-redux';
import { selectAppOrganizationId } from '../../../stores/GlobalApp/slice/selectors';
import { DataConnectorStatusView } from './components/DataConnectorStatusView/DataConnectorStatusView';
import { DataConnector } from '../../../types/dataconnector';
import { deleteDataConnector } from '../../../helpers/api/deleteDataConnector';

const getDataSourceIcon = (sourceType: DataSourceType): string => {
  switch (sourceType) {
    case DataSourceType.AWS_S3:
      return AWSS3Icon;
    case DataSourceType.NOTION:
      return NotionIcon;
    case DataSourceType.GOOGLE_CLOUD:
      return GoogleCloudIcon;
    case DataSourceType.GOOGLE_DRIVE:
      return GoogleDriveIcon;
    case DataSourceType.DROPBOX:
      return DropboxIcon;
    case DataSourceType.AZURE_BLOB:
    default:
      return AzureIcon;
  }
};

interface Props {
  dataConnector: DataConnector;
  reload: () => void;
}

export function DataConnectorCard(props: Props) {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const dataConnector = props.dataConnector;
  const organizationId = useSelector(selectAppOrganizationId);

  const onDelete = useCallback(() => {
    if (!organizationId) return;
    deleteDataConnector(organizationId, dataConnector.uuid).then(() => {
      props.reload();
    });
  }, [dataConnector.uuid, organizationId, props]);

  return (
    <div
      key={dataConnector.uuid}
      className="flex w-full flex-row items-center justify-between gap-x-3 rounded-[8px] border border-[#f3f3f3] bg-white px-3 py-2 hover:bg-[#fcfcfc]"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="_Left flex flex-row gap-x-3">
        <div className="_Left1 flex flex-col gap-y-1">
          <div className="flex w-full flex-row justify-between gap-x-1">
            <div className="text-[14px] font-semibold">Data Connector</div>
          </div>
          <div className="_Properties flex flex-col gap-y-0.25">
            <DataConnectorStatusView status={dataConnector.sync_status} />
          </div>
        </div>
      </div>
      {hover && (
        <div className="_Right flex flex-row items-center gap-x-1">
          <Button label="Delete" onClick={onDelete} type={ButtonType.SECONDARY}></Button>
        </div>
      )}
    </div>
  );
}
