import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Transition } from '@headlessui/react';
import { transitionClassesHelper } from '../../../helpers/transitionClassesHelper';
import { notificationsActions } from '../../../stores/GlobalNotifications/slice';

const TOAST_LIFETIME_MS = 10000;

interface Props {
  id: string;
  message: string;
  icon?: string;
}

export function Toast({ id, message }: Props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    // Self-destroy after 10s
    const timeoutId = setTimeout(() => {
      setShow(false);
    }, TOAST_LIFETIME_MS);

    // Clear timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, [dispatch, id]);

  return (
    <Transition
      show={show}
      as={Fragment}
      {...transitionClassesHelper.speeds.fast.toast}
      afterLeave={() => dispatch(notificationsActions.removeToast(id))}
    >
      <div
        key={id}
        className="rounded-[8px] bg-ozoneV2-grey-200 px-2 py-1.5 text-[13px] font-medium text-ozoneV2-grey-900"
      >
        {message}
      </div>
    </Transition>
  );
}
