import { OperationLogView } from '../GraphDetail/components/OperationLogView/OperationLogView';
import { useEffect, useState } from 'react';
import { OperationLog } from '../../../../../../../types/operationLog';
import { getGraphOperationLogs } from '../../../../../../../helpers/api/getGraphOperations';
import { useParams } from 'react-router-dom';

export function GraphOperations() {
  const { graphId } = useParams();
  const [operationLogs, setOperationLogs] = useState<Array<OperationLog> | null>(null);

  useEffect(() => {
    if (!graphId) return;
    if (operationLogs) return;
    getGraphOperationLogs(graphId).then(ol => setOperationLogs(ol));
  }, [graphId, operationLogs]);

  return (
    <div className="mt-2 flex h-full w-1/4 flex-col">
      <div className="flex flex-col gap-y-0.5">
        {operationLogs &&
          operationLogs.map(operationLog => <OperationLogView operationLog={operationLog} key={operationLog.uuid} />)}
      </div>
    </div>
  );
}
