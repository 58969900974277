import { RootState } from '../../../types/redux/RootState';
import { initialState } from './index';
import { createSelector } from '@reduxjs/toolkit';

const selectSlice = (state: RootState) => state?.app || initialState;

const selectApp = createSelector([selectSlice], state => state);

export const selectAllOrganizations = createSelector([selectSlice], state => state.organizations);

export const selectAppOrganizationId = createSelector([selectSlice], state => state.activeOrganizationId);

const selectAppOrganization = createSelector([selectSlice, selectAppOrganizationId], (state, uuid) =>
  state.organizations?.find(org => org.uuid === uuid),
);
export const selectAppOrganizationMetadata = createSelector(
  [selectAppOrganization],
  organization => organization?.metadata,
);

export const selectAppOrganizationName = createSelector([selectAppOrganization], organization => organization?.name);
export const selectAppOrganizationApiKeys = createSelector(
  [selectAppOrganization],
  organization => organization?.api_keys,
);

export const selectAppPlan = createSelector([selectAppOrganization], organization => organization?.subscription?.plan);
export const selectAppTeamMembers = createSelector([selectAppOrganization], organization => organization?.members);
export const selectAppStatus = createSelector([selectSlice], state => state.status);
