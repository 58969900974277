import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer } from '../../../utils/redux/redux-injectors';
import { LoadingStatus, UserState } from './types';
import { UserAttributes } from '../../../helpers/getUserAttributes';
import { User } from '../../../types/user';

export const initialState: UserState = {
  cognitoId: '',
  cognitoName: '',
  cognitoEmail: '',
  cognitoPicture: '',
  cognitoStatus: LoadingStatus.IDLE,
  userStatus: LoadingStatus.IDLE,
  user: null,
  cognitoJwtToken: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAttributes(state, action: PayloadAction<UserAttributes>) {
      state.cognitoId = action.payload.uuid;
      state.cognitoPicture = action.payload.picture;
      state.cognitoName = action.payload.name;
      state.cognitoEmail = action.payload.email;
    },
    updateCognitoLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.cognitoStatus = action.payload;
    },
    updateUserLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.userStatus = action.payload;
    },
    updateUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setUserJwtToken(state, action: PayloadAction<string>) {
      state.cognitoJwtToken = action.payload;
    },
  },
});

export const { actions: userActions } = slice;

export const useUserState = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
