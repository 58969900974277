import { ReactNode } from 'react';

interface Props {
  label: string;
  value: string;
  action?: ReactNode;
}

export function ValueView(props: Props) {
  return (
    <div className="flex max-w-40 flex-row p-4">
      <div className="_Left flex flex-col gap-y-4">
        <div className="text-[16px] font-semibold">{props.label}</div>
        <div className="text-[14px] font-medium">{props.value}</div>
      </div>
      {props.action && <div className="_Next ml-2 mt-0.25 flex">{props.action}</div>}
    </div>
  );
}
