import { signUpWithGoogle } from '../../../../../helpers/authHelper';
import { GoogleLogoIcon } from '../../../../components/Icons';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../../assets/logoBlack.png';
import { Input, InputType } from '../../../../components/Input/Input';
import { Button } from '../../../../components/Button/Button';
import { ButtonType } from '../../../../components/Button/Button.types';
import { useCallback, useState } from 'react';
import { confirmSignUp, signIn, signUp, resendSignUpCode } from '@aws-amplify/auth';
import { loadCognitoUser } from '../../../../../stores/GlobalUser/thunks/loadCognitoUser';
import { useDispatch } from 'react-redux';
import { APP_URL } from '../../../../../helpers/envHelper';

export enum Step {
  INITIAL,
  ENTER_CODE,
  SUCCESS,
}

interface Props {
  isSignup?: boolean;
}

export function LoginCard(props: Props) {
  const dispatch = useDispatch();
  const [step, setStep] = useState(Step.INITIAL);
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const params = query.toString();
  const paramsExist = params && params !== '';
  const redirectUrl = paramsExist ? APP_URL + '/login_successful?' + params : undefined;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const handleSignUp = useCallback(() => {
    signUp({ username: email, password: password })
      .then(output => {
        console.info(output);
        const isSignUpComplete = output.isSignUpComplete;
        if (isSignUpComplete) {
          setStep(Step.SUCCESS);
        } else {
          const signUpStep = output.nextStep.signUpStep;
          if (signUpStep === 'CONFIRM_SIGN_UP') {
            setStep(Step.ENTER_CODE);
            setError('');
          } else {
            setStep(Step.SUCCESS);
            setError('');
          }
        }
      })
      .catch((reason: any) => {
        const reasonStr = reason.toString();
        console.info(reasonStr);
        if (reasonStr.includes('InvalidPasswordException')) {
          setError('Choose another password!');
        } else if (reasonStr.includes('UsernameExistsException')) {
          setError('This user already exists!');
        } else if (reasonStr.includes('InvalidParameterException')) {
          setError('The email is not valid!');
        } else {
          setError('An unexpected error occurred!');
        }
      });
  }, [email, password]);

  const handleSignIn = useCallback(() => {
    signIn({ username: email, password: password })
      .then(output => {
        console.info(output);
        const isSignedIn = output.isSignedIn;
        const nextStep = output.nextStep.signInStep;
        if (isSignedIn) {
          setStep(Step.SUCCESS);
          dispatch(loadCognitoUser() as any);
          navigate('/');
        } else {
          if (nextStep === 'CONFIRM_SIGN_UP') {
            setStep(Step.ENTER_CODE);
            setError('');
          } else {
            setError('There was a problem authenticating your account.');
          }
        }
      })
      .catch((reason: any) => {
        const reasonStr = reason.toString();
        console.info(reasonStr);
        if (reasonStr.includes('NotAuthorizedException')) {
          setError('Incorrect username or password.');
        } else if (reasonStr.includes('UsernameExistsException')) {
          setError('Your email is not registered!');
        } else {
          setError('An unexpected error occurred!');
        }
      });
  }, [dispatch, email, navigate, password]);

  const handleSubmit = useCallback(() => {
    if (props.isSignup) {
      handleSignUp();
    } else {
      handleSignIn();
    }
  }, [handleSignIn, handleSignUp, props.isSignup]);

  const handleConfirmCode = useCallback(() => {
    confirmSignUp({ confirmationCode: code, username: email })
      .then(output => {
        console.info(output);
        const isComplete = output.isSignUpComplete;
        if (isComplete) {
          setStep(Step.SUCCESS);
          setError('');
          setMessage('');

          // Log in user automatically
          handleSignIn();
        } else {
          setStep(Step.INITIAL);
          setError('Incorrect code!');
          setMessage('');
        }
      })
      .catch((reason: any) => {
        const reasonStr = reason.toString();
        console.info(reasonStr);
        if (reasonStr.includes('CodeMismatchException')) {
          setError('Wrong confirmation code. Please try again.');
        } else {
          setError('An unexpected error occurred while confirming your sign in.');
        }
      });
  }, [code, email, handleSignIn]);

  const handleResendCode = useCallback(() => {
    resendSignUpCode({ username: email }).then(() => {
      setMessage('We resent your confirmation code.');
    });
  }, [email]);

  return (
    <div className="flex flex-col items-center gap-y-2.5">
      <div className="_LoginCard flex w-40 flex-col items-center justify-center rounded-[12px] border border-[#f4f4f4] bg-white p-4">
        <div className="mb-7 mt-5">
          <img src={logo} alt={'logo'} className="w-21" />
        </div>
        {step === Step.INITIAL && (
          <div className="flex w-full flex-col items-center justify-center">
            <button
              onClick={() => signUpWithGoogle(redirectUrl)}
              className="flex flex-row items-center gap-x-1.5 rounded-[8px] bg-[#F1F1F1] p-2 px-3 font-semibold text-ozoneV2-grey-500 transition-colors duration-150 hover:bg-[#F6F6F6]"
            >
              <GoogleLogoIcon className="size-3" />
              Sign {props.isSignup ? 'up' : 'in'} with Google
            </button>
            <div className="my-1.5">or</div>
            <div className="flex w-full flex-col gap-y-0.5 px-1">
              <Input placeholder={'Your email'} value={email} onChange={v => setEmail(v)} inputType={InputType.EMAIL} />
              {email.length > 0 && (
                <Input
                  placeholder={'Password'}
                  value={password}
                  onChange={v => setPassword(v)}
                  inputType={InputType.PASSWORD}
                />
              )}
              <Button label={props.isSignup ? 'Sign up' : 'Log in'} type={ButtonType.PRIMARY} onClick={handleSubmit} />
            </div>
          </div>
        )}
        {step === Step.ENTER_CODE && (
          <div className="flex w-full flex-col items-center justify-center gap-y-0.5">
            <div className="my-1.5 text-center">Enter the code that was sent to {email}:</div>
            <div className="flex w-full flex-col gap-y-0.5 px-1">
              {email.length > 0 && (
                <Input placeholder={'Your code'} value={code} onChange={v => setCode(v)} inputType={InputType.TEXT} />
              )}
              <Button label={'Confirm'} type={ButtonType.PRIMARY} onClick={handleConfirmCode} />
              <a /* eslint-disable-line jsx-a11y/anchor-is-valid */
                onClick={handleResendCode}
                className="mt-2 cursor-pointer text-center text-[13px] font-medium text-black"
              >
                Resend code
              </a>
            </div>
          </div>
        )}
        {step === Step.SUCCESS && (
          <div className="flex w-full flex-col items-center justify-center text-center">
            Success! You're being signed in automatically...
          </div>
        )}
        {error && <div className="mt-1.5 text-[12px] text-red-400">{error}</div>}
        {message && <div className="mt-1.5 text-[12px] text-green-700">{message}</div>}
        <div className="mt-4 text-center text-[11px] font-medium text-ozoneV2-grey-600">
          By submitting, you accept the{' '}
          <a className="cursor-pointer font-semibold text-ozoneV2-grey-400" href={`${APP_URL}/msa`}>
            Master Services Agreement
          </a>{' '}
          and{' '}
          <a className="cursor-pointer font-semibold text-ozoneV2-grey-400" href={`${APP_URL}/privacy-policy`}>
            Privacy Policy
          </a>
        </div>
      </div>
      <div className="text-[14px] font-medium text-ozoneV2-grey-400">
        {props.isSignup && (
          <div className="flex flex-row gap-x-0.5">
            Already have an account?
            <div
              className="cursor-pointer font-semibold text-ozoneV2-brand-blue-100"
              onClick={() => navigate(`/login?${params}`)}
            >
              Sign in
            </div>
          </div>
        )}
        {!props.isSignup && (
          <div className="flex flex-row gap-x-0.5">
            Don't have an account?
            <div
              className="cursor-pointer font-semibold text-ozoneV2-brand-blue-100"
              onClick={() => navigate(`/signup?${params}`)}
            >
              Sign up
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
