import { CheckIcon, CopyIcon } from '../Icons';
import { useState } from 'react';

interface Props {
  value: string;
}

export function CopyIndicator(props: Props) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(props.value).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    });
  };

  return (
    <div onClick={handleCopy} className="size-2 cursor-pointer">
      {copied ? (
        <CheckIcon className="size-full text-green-400" />
      ) : (
        <CopyIcon className="size-full text-ozoneV2-grey-700" />
      )}
    </div>
  );
}
