import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../types/redux/RootState';
import { appActions } from '../slice';
import { selectUserId } from '../../GlobalUser/slice/selectors';
import { LoadingStatus } from '../../GlobalUser/slice/types';
import { selectAppStatus } from '../slice/selectors';
import { getOrganizationsByUserId } from '../../../helpers/api/getOrganizationsByUserId';
import { createAndSelectOrganization } from './createOrganization';

export function fetchOrCreateOrganization() {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const state = getState();
    const userId = selectUserId(state);
    const appStatus = selectAppStatus(state);
    if (!userId) return;
    if (appStatus === LoadingStatus.LOADING) return;
    dispatch(appActions.updateStatus(LoadingStatus.LOADING));
    getOrganizationsByUserId(userId).then(organizations => {
      if (organizations && organizations.length >= 1) {
        // Update organizations
        dispatch(appActions.updateOrganizations(organizations));
        dispatch(appActions.updateStatus(LoadingStatus.SUCCEEDED));
        const cachedActiveOrganizationId = localStorage.getItem('activeOrganizationId');
        if (cachedActiveOrganizationId) {
          dispatch(appActions.updateActiveOrganizationId(cachedActiveOrganizationId));
        } else {
          dispatch(appActions.updateActiveOrganizationId(organizations[0].uuid));
        }
      } else {
        // Organizations not found. Create one.
        dispatch(createAndSelectOrganization());
        dispatch(appActions.updateStatus(LoadingStatus.SUCCEEDED));
      }
    });
  };
}
