import { fetchApi } from '../fetchApi';
import { DataSource } from '../../types/datasource';

export async function listDataSources(organizationId: string): Promise<Array<DataSource>> {
  try {
    const data = await fetchApi('list_data_sources', {
      organization_uuid: organizationId,
    });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
