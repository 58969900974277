import { fetchApi } from '../fetchApi';
import { DataSource } from '../../types/datasource';

export async function createDataSource(organizationId: string, dataSource: Partial<DataSource>): Promise<DataSource> {
  try {
    const data = await fetchApi('create_data_source', { organization_uuid: organizationId, data_source: dataSource });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
