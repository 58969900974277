import { fetchApi } from '../fetchApi';
import { DataConnector } from '../../types/dataconnector';

export async function listDataConnectors(organizationId: string, graphId: string): Promise<Array<DataConnector>> {
  try {
    const data = await fetchApi('list_data_connectors', {
      organization_uuid: organizationId,
      graph_uuid: graphId,
    });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
