import { TabHeader } from '../../components/TabHeader/TabHeader';
import { Button } from '../../../../components/Button/Button';
import { ButtonType } from '../../../../components/Button/Button.types';
import { TabContent } from '../../components/TabContent/TabContent';
import { TabView } from '../../components/TabView/TabView';
import { APIKeyCard } from '../../../../components/APIKeyCard/APIKeyCard';
import { useSelector } from 'react-redux';
import { selectAppOrganizationApiKeys } from '../../../../../stores/GlobalApp/slice/selectors';

export function ApiKeysTab() {
  const keys = useSelector(selectAppOrganizationApiKeys);

  return (
    <TabView>
      <TabHeader>
        <div className="font-semibold">API Keys</div>
        <Button label="Create Key" type={ButtonType.PRIMARY} onClick={() => undefined} disabled={true} />
      </TabHeader>
      <TabContent>
        {keys && keys.map(apiKey => <APIKeyCard key={apiKey.name} name={apiKey.name} value={apiKey.value} />)}
      </TabContent>
    </TabView>
  );
}
