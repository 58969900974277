export const transitionClassesHelper = {
  speeds: {
    slow: {
      contextMenu: {
        enter: 'transition ease-out duration-1000',
        enterFrom: 'transform opacity-0 scale-95',
        enterTo: 'transform opacity-100 scale-100',
        leave: 'transition ease-in duration-500',
        leaveFrom: 'transform opacity-100 scale-100',
        leaveTo: 'transform opacity-0 scale-95',
      },
      dropdownMenu: {
        enter: 'transition ease-out duration-1000',
        enterFrom: 'transform opacity-0 scale-95',
        enterTo: 'transform opacity-100 scale-100',
        leave: 'transition ease-in duration-500',
        leaveFrom: 'transform opacity-100 scale-100',
        leaveTo: 'transform opacity-0 scale-95',
      },
      fadeInZoom: {
        enter: 'ease-out duration-1000',
        enterFrom: 'opacity-0 scale-95',
        enterTo: 'opacity-100 scale-100',
        leave: 'ease-in duration-500',
        leaveFrom: 'opacity-100 scale-100',
        leaveTo: 'opacity-0 scale-95',
      },
      flyInFromRight: {
        enter: 'transform transition ease-in-out duration-1000',
        enterFrom: 'translate-x-full',
        enterTo: 'translate-x-0',
        leave: 'transform transition ease-in-out duration-500',
        leaveFrom: 'translate-x-0',
        leaveTo: 'translate-x-full',
      },
    },
    medium: {
      contextMenu: {
        enter: 'transition ease-out duration-500',
        enterFrom: 'transform opacity-0 scale-95',
        enterTo: 'transform opacity-100 scale-100',
        leave: 'transition ease-in duration-[250ms]',
        leaveFrom: 'transform opacity-100 scale-100',
        leaveTo: 'transform opacity-0 scale-95',
      },
      dropdownMenu: {
        enter: 'transition ease-out duration-500',
        enterFrom: 'transform opacity-0 scale-95',
        enterTo: 'transform opacity-100 scale-100',
        leave: 'transition ease-in duration-[250ms]',
        leaveFrom: 'transform opacity-100 scale-100',
        leaveTo: 'transform opacity-0 scale-95',
      },
      fadeInZoom: {
        enter: 'ease-out duration-500',
        enterFrom: 'opacity-0 scale-95',
        enterTo: 'opacity-100 scale-100',
        leave: 'ease-in duration-[250ms]',
        leaveFrom: 'opacity-100 scale-100',
        leaveTo: 'opacity-0 scale-95',
      },
      flyInFromRight: {
        enter: 'transform transition ease-in-out duration-500',
        enterFrom: 'translate-x-full',
        enterTo: 'translate-x-0',
        leave: 'transform transition ease-in-out duration-[250ms]',
        leaveFrom: 'translate-x-0',
        leaveTo: 'translate-x-full',
      },
    },
    fast: {
      contextMenu: {
        enter: 'transition ease-out duration-[50ms]',
        enterFrom: 'transform opacity-90 scale-[98%]',
        enterTo: 'transform opacity-100 scale-100',
        leave: 'transition ease-out duration-[50ms]',
        leaveFrom: 'transform opacity-100 scale-100',
        leaveTo: 'transform opacity-90 scale-[98%]',
      },
      dropdownMenu: {
        enter: 'transition ease-out duration-[75ms]',
        enterFrom: 'transform opacity-80 scale-[97%]',
        enterTo: 'transform opacity-100 scale-100',
        leave: 'transition ease-in duration-75',
        leaveFrom: 'transform opacity-100 scale-100',
        leaveTo: 'transform opacity-80 scale-[97%]',
      },
      fadeInZoom: {
        enter: 'ease-out duration-[150ms]',
        enterFrom: 'opacity-0 scale-95',
        enterTo: 'opacity-100 scale-100',
        leave: 'ease-in duration-75',
        leaveFrom: 'opacity-100 scale-100',
        leaveTo: 'opacity-0 scale-95',
      },
      flyInFromRight: {
        enter: 'transform transition ease-in-out duration-[150ms]',
        enterFrom: 'translate-x-full',
        enterTo: 'translate-x-0',
        leave: 'transform transition ease-in-out duration-75',
        leaveFrom: 'translate-x-0',
        leaveTo: 'translate-x-full',
      },
      propertySubMenu: {
        enter: 'transition ease-out duration-[30ms]',
        enterFrom: 'transform opacity-80 scale-[97%]',
        enterTo: 'transform opacity-100 scale-100',
        leave: 'transition ease-in duration-75',
        leaveFrom: 'transform opacity-100 scale-100',
        leaveTo: 'transform opacity-80 scale-[97%]',
      },
      toast: {
        enter: 'ease-out duration-[150ms]',
        enterFrom: 'opacity-0 scale-95',
        enterTo: 'opacity-100 scale-100',
        leave: 'ease-in duration-75',
        leaveFrom: 'opacity-100 scale-100',
        leaveTo: 'opacity-0 scale-95',
      },
    },
  },
};
