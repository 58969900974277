import { ConversationHistory } from '../../types/conversationHistory';
import { fetchApi } from '../fetchApi';

export async function submitPrompt(graphId: string, prompt: string, searchType?: string): Promise<ConversationHistory> {
  try {
    const data = await fetchApi('submit_prompt', {
      graph_uuid: graphId,
      prompt: prompt,
      search_type: searchType,
    });

    return data;
  } catch (error) {
    console.error('Error:', error);
    alert('Query operation failed: ' + error);
    throw error;
  }
}
