import { TeamMemberRole } from '../../types/teamMember';
import { fetchApi } from '../fetchApi';

export async function inviteTeamMember(organizationId: string, email: string, role: TeamMemberRole): Promise<null> {
  try {
    const data = await fetchApi('invite_member', {
      organization_uuid: organizationId,
      email: email,
      role: role,
    });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
