import { User } from '../../../types/user';

export enum LoadingStatus {
  IDLE,
  LOADING,
  SUCCEEDED,
  FAILED,
}

export interface UserState {
  cognitoId: string;
  cognitoEmail: string;
  cognitoName: string;
  cognitoPicture: string;
  userStatus: LoadingStatus;
  user: User | null;
  cognitoStatus: LoadingStatus;
  cognitoJwtToken: string | null;
}
