import { IconCheck } from '@aws-amplify/ui-react/internal';
import { Button } from '../../../../../../components/Button/Button';
import { ButtonType } from '../../../../../../components/Button/Button.types';
import { CheckIcon } from '../../../../../../components/Icons';

interface Props {
  label: string;
  description: string;
  addOns: Array<string>;
  isCurrent: boolean;
  onClick: () => void;
  buttonLabel?: string;
  maxGraphs?: number;
  maxStorage?: number;
  maxNamespaces?: number;
  maxWrites?: number;
  maxReads?: number;
  storageRate?: number;
  writesRate?: number;
  readsRate?: number;
}

export function PlanCard(props: Props) {
  const {
    label,
    description,
    addOns,
    isCurrent,
    onClick,
    buttonLabel,
    maxGraphs,
    maxStorage,
    maxWrites,
    maxReads,
    maxNamespaces,
    writesRate,
    storageRate,
    readsRate,
  } = props;

  return (
    <div
      className={`flex w-45 flex-col justify-between rounded-[12px] border-gray-300 bg-gray-50 p-4 pt-3 font-medium`}
    >
      <div className="_Top flex flex-col">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-[32px] font-bold">{label}</h2>
            <p className="text-[16px] font-medium text-gray-600">{description}</p>
          </div>
          {isCurrent && <CheckIcon className="size-3 text-green-500" />}
        </div>
        <div className="mt-2">
          <div className="mb-1 text-[20px] font-semibold">Usage</div>
          <div className="flex items-center text-[14px] text-gray-700">
            <IconCheck className="ssize-2 mr-2 text-green-500" />
            <p className="pt-0.5">{maxGraphs !== undefined ? `Up to ${maxGraphs} graphs` : 'Unlimited graphs'}</p>
          </div>
          <div className="flex items-center text-[14px] text-gray-700">
            <IconCheck className="mr-2 size-2 text-green-500" />
            <p className="pt-0.5">
              {maxStorage !== undefined
                ? `Up to ${maxStorage} MB storage`
                : `Unlimited storage ($${storageRate} / GB / hr)`}
            </p>
          </div>
          <div className="flex items-center text-[14px] text-gray-700">
            <IconCheck className="mr-2 size-2 text-green-500" />
            <p className="pt-0.5">
              {maxWrites !== undefined
                ? `Up to ${maxWrites} write units`
                : `Unlimited writes ($${writesRate} / 1k write units)`}
            </p>
          </div>
          <div className="flex items-center text-[14px] text-gray-700">
            <IconCheck className="mr-2 size-2 text-green-500" />
            <p className="pt-0.5">
              {maxReads !== undefined
                ? `Up to ${maxReads} read units`
                : `Unlimited reads ($${readsRate} / 1k read units)`}
            </p>
          </div>
          <div className="flex items-center text-[14px] text-gray-700">
            <IconCheck className="mr-2 size-2 text-green-500" />
            <p className="pt-0.5">
              {maxNamespaces !== undefined
                ? `Up to ${maxNamespaces} namespaces (coming soon)`
                : 'Unlimited namespaces (coming soon)'}
            </p>
          </div>
        </div>
        <ul className="mt-3">
          <div className="text-[20px] font-semibold">Features</div>
          {addOns.map((addOn, index) => (
            <li key={index} className="flex items-center text-[14px] text-gray-700">
              <IconCheck className="mr-2 size-2 text-green-500" />
              <p className="pt-0.5">{addOn}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="_Bottom mt-2 flex justify-center">
        <Button
          label={(isCurrent ? 'Current Plan' : props.buttonLabel) ?? 'Upgrade'}
          onClick={onClick}
          type={ButtonType.PRIMARY}
          disabled={isCurrent}
        />
      </div>
    </div>
  );
}
