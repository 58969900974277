import React, { useEffect, useMemo, useState } from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import { getGraphNodes } from '../../../../../../../../../helpers/api/getGraphNodes';
import { getGraphEdges } from '../../../../../../../../../helpers/api/getGraphEdges';
import { useParams } from 'react-router-dom';
import { GraphEdge, GraphNode } from '../../../../../../../../../types/graph';
import cytoscape, { ElementDefinition } from 'cytoscape';
import { CloseIcon } from '../../../../../../../../components/Icons';
import { stripString } from '../../../../../../../../../utils/stripString'; // Ensure fcose layout is available
// @ts-ignore
import coseBilkent from 'cytoscape-cose-bilkent';
import { capitalizeWords } from '../../../../../../../../../utils/capitalizeWords';
import { LoadingCircle } from '../../../../../../../../components/LoadingCircle/LoadingCircle';
cytoscape.use(coseBilkent);

// @ts-ignore
interface CoseBilkentLayoutOptions extends cytoscape.LayoutOptions {
  name: 'cose-bilkent';
  animate?: boolean;
  nodeRepulsion?: number;
  idealEdgeLength?: number;
  gravity?: number;
  edgeElasticity?: number;
  numIter?: number;
  randomize?: boolean;
  tile?: boolean;
  nodeDimensionsIncludeLabels?: boolean;
}

type NodeTypeColorMap = {
  [key: string]: string;
};

const COLOR_RANGE = [
  '#FF5733',
  '#33AFFF',
  '#33FF57',
  '#FF33A1',
  '#FFD433',
  '#8D33FF',
  '#33FFF4',
  '#FF9133',
  '#FF5733',
  '#FFC300',
  '#DAF7A6',
  '#C70039',
  '#900C3F',
  '#581845',
  '#2ECC71',
  '#1ABC9C',
  '#3498DB',
  '#9B59B6',
  '#34495E',
  '#F39C12',
  '#D35400',
  '#E74C3C',
  '#2C3E50',
  '#E67E22',
  '#16A085',
  '#27AE60',
  '#2980B9',
  '#8E44AD',
  '#2E4053',
  '#58D68D',
  '#F4D03F',
  '#DC7633',
  '#AF7AC5',
  '#5DADE2',
  '#F5B7B1',
  '#82E0AA',
  '#A9CCE3',
  '#BB8FCE',
  '#F1948A',
  '#F7DC6F',
  '#52BE80',
  '#F8C471',
  '#F5B041',
  '#3498DB',
  '#AED6F1',
  '#5D6D7E',
  '#2E4053',
  '#212F3D',
];

function isGraphNode(edgeNode: any): edgeNode is GraphNode {
  return (
    typeof edgeNode.name === 'string' && typeof edgeNode.type === 'string' && typeof edgeNode.description === 'string'
  );
}

let colorIndex = 0;
const nodeTypeColorMap: NodeTypeColorMap = {};

// Assign a color to each node type
const getColorForNodeType = (type: string): string => {
  if (!nodeTypeColorMap[type]) {
    nodeTypeColorMap[type] = COLOR_RANGE[colorIndex % COLOR_RANGE.length];
    colorIndex++;
  }
  return nodeTypeColorMap[type];
};

const elementFromNode = (node: GraphNode): ElementDefinition => {
  const nodeColor = getColorForNodeType(node.type);
  return {
    data: {
      id: node.name,
      label: capitalizeWords(stripString(node.name).toLowerCase()),
      type: node.type,
      backgroundColor: nodeColor,
    },
    classes: node.type, // Add class based on node type
  };
};

const elementFromEdge = (edge: GraphEdge): ElementDefinition => {
  return {
    data: {
      label: edge.description,
      source: edge.entity_1,
      target: edge.entity_2,
    },
    style: {},
  };
};

function findAllEntityTypes(nodes: GraphNode[]): string[] {
  // Use a Set to automatically filter out duplicate types
  const entityTypeSet = new Set<string>();

  // Iterate over the nodes and add each type to the Set
  nodes.forEach(node => {
    entityTypeSet.add(node.type);
  });

  // Convert the Set back to an array and return it
  return Array.from(entityTypeSet);
}

export function GraphViewport() {
  const { graphId } = useParams();
  const [nodes, setNodes] = useState<Array<GraphNode> | null>(null);
  const [edges, setEdges] = useState<Array<GraphEdge> | null>(null);
  const [elements, setElements] = useState<ElementDefinition[] | null>(null);
  const [selectedNodeEdge, setSelectedNodeEdge] = useState<GraphNode | GraphEdge | null>(null);
  const nodeCount = nodes?.length;
  const edgeCount = edges?.length;
  const entityTypes = useMemo(() => findAllEntityTypes(nodes ?? []), [nodes]);

  const handleElementClick = (event: any) => {
    if (!edges) return;
    if (!nodes) return;
    const element = event.target;
    const isEdge = element.isEdge();

    if (isEdge) {
      const description = element.data('label');
      const edge = edges.find(edge => edge.description === description);
      if (edge) {
        setSelectedNodeEdge(edge);
      }
    } else {
      const label = element.data('id');
      const node = nodes.find(node => node.name === label);
      if (node) {
        setSelectedNodeEdge(node);
      }
    }
  };

  const handleElementUnselect = () => {
    setSelectedNodeEdge(null);
  };

  const handleCloseOverlay = () => {
    setSelectedNodeEdge(null);
  };

  useEffect(() => {
    if (!graphId) return;

    const fetchGraphData = async () => {
      try {
        const results = await Promise.allSettled([getGraphNodes(graphId), getGraphEdges(graphId)]);

        const nodesResult = results[0];
        const edgesResult = results[1];

        const nodes: GraphNode[] =
          nodesResult.status === 'fulfilled' && Array.isArray(nodesResult.value) ? nodesResult.value : [];

        const edges: GraphEdge[] =
          edgesResult.status === 'fulfilled' && Array.isArray(edgesResult.value) ? edgesResult.value : [];

        if (nodesResult.status === 'rejected' || edgesResult.status === 'rejected') {
          console.error('Error fetching graph data:', {
            nodesError: nodesResult.status === 'rejected' ? nodesResult.reason : null,
            edgesError: edgesResult.status === 'rejected' ? edgesResult.reason : null,
          });
        }

        const newElements = [...nodes.map(node => elementFromNode(node)), ...edges.map(edge => elementFromEdge(edge))];

        setElements(newElements);
        setNodes(nodes);
        setEdges(edges);
      } catch (error) {
        console.error('Unexpected error:', error);
      }
    };

    fetchGraphData().then(() => console.info('Graph data loaded up.'));
  }, [graphId]);

  if (elements === null)
    return (
      <div className="flex size-full flex-row items-center justify-center">
        <LoadingCircle />
      </div>
    );

  if (!elements || elements?.length === 0)
    return <div className="flex size-full flex-row items-center justify-center">This graph is empty.</div>;

  return (
    <div className="relative size-full">
      <CytoscapeComponent
        elements={elements}
        style={{ width: '100%', height: '100%' }}
        layout={
          {
            name: 'cose-bilkent',
            animate: true,
            nodeRepulsion: 10000,
            idealEdgeLength: 300,
            gravity: 0.25,
            edgeElasticity: 1.5,
            randomize: true,
          } as CoseBilkentLayoutOptions
        }
        stylesheet={[
          {
            selector: 'node[backgroundColor]', // Apply styles to nodes with defined backgroundColor
            style: {
              label: 'data(label)',
              color: '#000',
              'text-valign': 'center',
              'text-halign': 'center',
              'background-color': 'data(backgroundColor)', // Use backgroundColor from data
            },
          },
          {
            selector: 'edge',
            style: {
              width: 1,
              'line-color': '#dfdfdf',
              'curve-style': 'unbundled-bezier',
              label: 'data(label)',
              'text-margin-y': -10,
              'font-size': '10px',
              color: '#000',
              'text-outline-width': 0,
              'text-opacity': 0,
            },
          },
        ]}
        cy={cy => {
          cy.on('tap', 'node, edge', handleElementClick);

          cy.on('tap', event => {
            if (event.target === cy) {
              handleElementUnselect();
            }
          });
        }}
      />

      {/* Info Box */}
      <div className="absolute left-2 top-4 z-50 rounded-[8px] border bg-white p-2.5 text-[11px] shadow-md">
        {/* Node Count */}
        <p>
          <strong>{nodes ? nodes.length : 0}</strong> nodes
        </p>

        {/* Edge Count */}
        <p>
          <strong>{edges ? edges.length : 0}</strong> edges
        </p>

        {/* Entity Types Legend */}
        <h4 className="my-2 font-semibold">Entity Types</h4>
        <div className="flex flex-col space-y-0.5">
          {entityTypes.map(type => (
            <div key={type} className="flex flex-row items-center font-medium">
              {/* Display color circle for entity type */}
              <div className="mr-2 size-1 rounded-full" style={{ backgroundColor: getColorForNodeType(type) }} />
              <span>{capitalizeWords(stripString(type).toLowerCase())}</span>
            </div>
          ))}
        </div>
      </div>

      {/* View Panel */}
      {selectedNodeEdge && (
        <div className="absolute right-0 top-0 z-50 h-full w-[400px] border-l bg-white p-5">
          <button onClick={handleCloseOverlay} style={{ float: 'right', cursor: 'pointer' }}>
            <CloseIcon className="size-2 text-ozoneV2-grey-600" />
          </button>

          <div className="flex size-full select-text flex-col gap-y-1 overflow-y-scroll text-[14px]">
            {/* Display either "Entity" or "Relationship" based on whether it's a node or edge */}
            <h2 className="mb-2 text-[16px] font-semibold">
              {isGraphNode(selectedNodeEdge) ? 'Entity' : 'Relationship'}
            </h2>

            {isGraphNode(selectedNodeEdge) && (
              <p>
                <strong>Name:</strong>{' '}
                {capitalizeWords(stripString((selectedNodeEdge as GraphNode).name).toLowerCase())}
              </p>
            )}

            {isGraphNode(selectedNodeEdge) && (
              <p>
                <strong>Type:</strong>{' '}
                {capitalizeWords(stripString((selectedNodeEdge as GraphNode).type).toLowerCase())}
              </p>
            )}

            <p>
              <strong>Description:</strong> {stripString(selectedNodeEdge.description)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
