import { signOut } from '@aws-amplify/auth';

export const logout = async (): Promise<void> => {
  try {
    await signOut();
    localStorage.clear();
  } catch (error) {
    console.error('Error during logout:', error);
  }
};

const useLogoutUser = () => {
  return { logout };
};

export default useLogoutUser;
