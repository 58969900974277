export enum DocumentUploadStatus {
  Pending = 'pending',
  Uploaded = 'uploaded',
  Uploading = 'uploading',
  Failed = 'failed',
}

export interface DocumentMetadata {
  uuid: string;
  file_name: string;
  account_id: string;
  namespace?: string;
  metadata?: Record<string, any>;
  upload_time: Date;
  status: DocumentUploadStatus;
  size_bytes: number;
}
