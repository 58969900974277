interface Props {
  label: string;
  maxValue: number;
  value: number;
  unit: string;
}

export function UsageIndicator(props: Props) {
  return (
    <div className="flex flex-col gap-y-0.5">
      <div className="flex flex-row justify-between">
        <div className="text-[14px] font-medium">{props.label}</div>
        <div className="text-[14px] font-semibold">
          {props.value} / {props.maxValue}
          {props.unit}
        </div>
      </div>
      <div className="_ProgressBar relative h-1 overflow-hidden rounded-[10px] bg-white">
        <div
          className="_ProgressBar absolute inset-y-0 left-0 bg-ozoneV2-brand-blue-primary"
          style={{ width: `${(props.value / props.maxValue) * 100}%` }}
        ></div>
      </div>
    </div>
  );
}
