import { useEffect, useRef, useState } from 'react';

export enum InputType {
  TEXT = 'text',
  EMAIL = 'email',
  PASSWORD = 'password',
}

interface Props {
  placeholder: string;
  value: string;
  onChange: (v: string) => void;
  initiallyFocused?: boolean;
  inputType?: InputType;
}

export function Input({ placeholder, value, onChange, initiallyFocused, inputType }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const baseClasses = 'px-2 py-1.5 rounded-[6px] font-semibold text-[13px] focus:outline-none duration-150';

  const inputClasses = `${baseClasses} border-[1.5px] border-gray-200 ${isFocused ? 'border-blue-500 hover:border-blue-500' : 'hover:border-blue-500/40'} max-w-40`;

  useEffect(() => {
    if (initiallyFocused && inputRef.current) {
      inputRef.current.focus();
      setIsFocused(true);
    }
  }, [initiallyFocused]);

  return (
    <input
      ref={inputRef}
      type={inputType ? inputType : InputType.TEXT}
      className={inputClasses}
      placeholder={placeholder}
      value={value}
      onChange={event => onChange(event.target.value)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    />
  );
}
