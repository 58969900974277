export enum OperationLogType {
  Index = 'index',
  Query = 'query',
}

export interface OperationLog {
  uuid: string;
  graph_uuid: string;
  operation_type: OperationLogType;
  timestamp: string;
  duration: number;
  llm: string;
  tokens: number;
  metadata?: object;
}
