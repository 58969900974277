import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGraphs } from '../thunks/fetchGraphs';
import { selectGraphsLoadingStatus } from '../slice/selectors';
import { LoadingStatus } from '../../GlobalUser/slice/types';
import { selectAppOrganizationId } from '../../GlobalApp/slice/selectors';

export function useFetchGraphs() {
  const dispatch = useDispatch();
  const graphsStatus = useSelector(selectGraphsLoadingStatus);
  const organizationId = useSelector(selectAppOrganizationId);
  const prevOrganizationId = useRef<string | null>(null);

  const fetchGraphData = useCallback(() => {
    dispatch(fetchGraphs() as any);
  }, [dispatch]);

  // Fetch graphs on organization id change
  useEffect(() => {
    if (!prevOrganizationId) return;

    if (prevOrganizationId.current !== organizationId) {
      fetchGraphData();
      prevOrganizationId.current = organizationId;
    }
  }, [fetchGraphData, organizationId]);

  // Fetch graph immediately
  useEffect(() => {
    if (graphsStatus !== LoadingStatus.IDLE) return;
    fetchGraphData();
  }, [dispatch, fetchGraphData, graphsStatus, organizationId]);

  // Fetch graphs periodically
  useEffect(() => {
    const intervalId = setInterval(fetchGraphData, 5000);
    return () => clearInterval(intervalId);
  }, []);
}
