import { useDispatch, useSelector } from 'react-redux';
import { selectCognitoUserStatus } from '../slice/selectors';
import { useEffect } from 'react';
import { LoadingStatus } from '../slice/types';
import { loadCognitoUser } from '../thunks/loadCognitoUser';

export function useLoadCognitoUser() {
  const dispatch = useDispatch();
  const userStatus = useSelector(selectCognitoUserStatus);

  useEffect(() => {
    if (userStatus !== LoadingStatus.IDLE) return;
    dispatch(loadCognitoUser() as any);
  }, [dispatch, userStatus]);
}
