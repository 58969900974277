import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { acceptInvite } from '../../../helpers/api/acceptInvite';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../../stores/GlobalUser/slice/selectors';
import { getUserAttributes } from '../../../helpers/getUserAttributes';
import { LoadingCircle } from '../../components/LoadingCircle/LoadingCircle';

export function AcceptInvitePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const userId = useSelector(selectUserId);

  useEffect(() => {
    // Extract the token from the URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    getUserAttributes().then(userAttributes => {
      const isUserLoggedIn = userAttributes && userAttributes.uuid && userAttributes.uuid !== '';

      if (!isUserLoggedIn) {
        // Redirect to signup page with token
        navigate('/signup?token=' + token);
        return;
      }

      if (!token) {
        setError('No invite token provided.');
        setLoading(false);
        return;
      }

      // Call the backend to validate and accept the invite
      acceptInvite(userId, token)
        .then(() => {
          navigate('/');
        })
        .catch(() => {
          setLoading(false);
          setError('Error while accepting invite.');
        });
    });
  }, [location.search, navigate, userId]);

  return (
    <div>
      {loading ? (
        <LoadingCircle />
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <p>Your invitation has been successfully accepted. Redirecting...</p>
      )}
    </div>
  );
}
