import { TabContent } from '../../components/TabContent/TabContent';
import { TabView } from '../../components/TabView/TabView';
import { TabHeader } from '../../components/TabHeader/TabHeader';
import { ButtonType } from '../../../../components/Button/Button.types';
import { Button } from '../../../../components/Button/Button';
import { AddIcon } from '../../../../components/Icons';
import { useCallback, useEffect, useState } from 'react';
import { DataSource } from '../../../../../types/datasource';
import { useSelector } from 'react-redux';
import { selectAppOrganizationId, selectAppPlan } from '../../../../../stores/GlobalApp/slice/selectors';
import { DataSourceCard } from '../../../../components/DataSourceCard/DataSourceCard';
import { DataSourceModal } from '../../../../components/DataSourceModal/DataSourceModal';
import { listDataSources } from '../../../../../helpers/api/listDataSources';
import { LoadingCircle } from '../../../../components/LoadingCircle/LoadingCircle';
import { SubscriptionPlan } from '../../../../../types/organization';

export function DataSourcesTab() {
  const organizationId = useSelector(selectAppOrganizationId);
  const currentPlan = useSelector(selectAppPlan);
  const [dataSources, setDataSources] = useState<Array<DataSource> | null>(null);
  const [dataSourceModalOpen, setDataSourceModalOpen] = useState<boolean>(false);

  const onCreateNew = () => {
    setDataSourceModalOpen(true);
  };

  const onClose = () => {
    setDataSourceModalOpen(false);
    fetchDataSources();
  };

  const fetchDataSources = useCallback(() => {
    if (!organizationId) return;
    listDataSources(organizationId).then(res => setDataSources(res));
  }, [organizationId]);

  const handleReload = useCallback(() => {
    fetchDataSources();
  }, [fetchDataSources]);

  useEffect(() => {
    fetchDataSources();
  }, [fetchDataSources, organizationId]);

  return (
    <TabView>
      <TabHeader>
        <div className="flex flex-row gap-x-1 font-semibold">
          <div>Data Sources</div>
        </div>
        <div className="flex flex-row gap-x-1.5">
          <Button
            icon={<AddIcon />}
            label="Data source"
            type={ButtonType.PRIMARY}
            onClick={onCreateNew}
            disabled={currentPlan !== SubscriptionPlan.ENTERPRISE}
          />
        </div>
      </TabHeader>
      <TabContent>
        <div className="flex size-full w-full flex-col gap-y-1">
          {currentPlan !== SubscriptionPlan.ENTERPRISE && (
            <div className="flex size-full w-full flex-col items-center justify-center gap-y-1">
              {<LoadingCircle />}
              {
                <div className="flex max-w-[50%] text-center">
                  Data sources are currently in beta testing and not supported on your plan. Please reach out to support
                  to request a specific integration (AWS S3, Azure Blob Storage, etc.) and we will be glad to assist!
                </div>
              }
            </div>
          )}
          {dataSources &&
            dataSources.map(dataSource => (
              <DataSourceCard key={dataSource.uuid} dataSource={dataSource} reload={handleReload} />
            ))}
        </div>
      </TabContent>
      <DataSourceModal open={dataSourceModalOpen} onClose={onClose} />
    </TabView>
  );
}
