export enum GraphIndexInterval {
  IMMEDIATE = 'IMMEDIATE',
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
}

export enum GraphStatus {
  STARTING = 'STARTING',
  ONLINE = 'ONLINE',
  STOPPING = 'STOPPING',
  OFFLINE = 'OFFLINE',
  INDEXING = 'INDEXING',
  SCHEDULED_INDEXING = 'SCHEDULED_INDEXING',
  FAILED = 'FAILED',
}

export interface IndexLlmConfig {
  model: string;
}

export interface EntityExtractionStrategy {
  name: string;
}

export interface EntityExtractionConfig {
  llm: IndexLlmConfig;
  entity_types: Array<string>;
  strategy: EntityExtractionStrategy;
}

export interface IndexConfig {
  entity_extraction: EntityExtractionConfig;
}

export interface Graph {
  uuid: string;
  name: string;
  created_at: string; // ISO date
  host: string;
  status: GraphStatus;
  index_interval: GraphIndexInterval;
  last_indexed?: Date;
  node_count: string;
  edge_count: string;
  summarized_graphml_url: string;
  index_config?: IndexConfig;
}

export interface GraphNode {
  name: string;
  type: string;
  description: string;
}

export interface GraphEdge {
  entity_1: string;
  entity_2: string;
  description: string;
  predicate: string;
}
