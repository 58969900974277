import { DocumentMetadata } from '../../../types/documentMetadata';
import { useEffect, useMemo, useState } from 'react';
import { DocumentRow } from './components/DocumentRow/DocumentRow';
import { getDocumentsMeta } from '../../../helpers/api/getDocumentsMeta';
import { useParams } from 'react-router-dom';
import { LoadingCircle } from '../LoadingCircle/LoadingCircle';

interface DocumentsListProps {
  currentlyUploadingDocuments: DocumentMetadata[];
}

export function DocumentsList(props: DocumentsListProps) {
  const { graphId } = useParams();

  const [documents, setDocuments] = useState<DocumentMetadata[] | null>(null);

  useEffect(() => {
    if (!graphId) return;
    getDocumentsMeta(graphId).then(docs => setDocuments(docs));
  }, [graphId]);

  const combinedDocuments = useMemo(() => {
    if (documents === null && props.currentlyUploadingDocuments.length === 0) {
      return null;
    }
    if (documents?.length === 0 && props.currentlyUploadingDocuments.length === 0) {
      return [];
    }
    return [...(documents ?? []), ...props.currentlyUploadingDocuments];
  }, [documents, props.currentlyUploadingDocuments]);

  return (
    <div className="flex w-full flex-col gap-y-1">
      {combinedDocuments === null ? (
        <div className="p-4 text-center text-gray-500">
          <LoadingCircle />
        </div>
      ) : combinedDocuments.length === 0 ? (
        <div className="p-4 text-center text-gray-500">No documents available</div>
      ) : (
        combinedDocuments.map(doc => <DocumentRow key={doc.uuid} documentMetadata={doc} />)
      )}
    </div>
  );
}
