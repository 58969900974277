import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { GraphStatusView } from './components/GraphStatusView/GraphStatusView';
import { Graph } from '../../../types/graph';
import { Button } from '../Button/Button';
import { ButtonType } from '../Button/Button.types';
import { Modal } from '../Modal/Modal';
import { CodeBlock } from '../CodeBlock/CodeBlock';

interface Props {
  graph: Graph;
}

export function GraphCard(props: Props) {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const graph = props.graph;
  const [connectOpen, setConnectOpen] = useState(false);

  const onConnect = useCallback(() => {
    setConnectOpen(true);
  }, []);

  const handleOpen = () => setConnectOpen(true);
  const handleClose = () => setConnectOpen(false);

  const onDetail = useCallback(() => {
    navigate('/graphs/' + graph.uuid);
  }, [graph.uuid, navigate]);

  return (
    <div
      key={graph.uuid}
      className="flex w-full cursor-pointer flex-row items-center justify-between gap-x-3 rounded-[8px] border border-[#f3f3f3] bg-white px-3 py-2 hover:bg-[#fcfcfc]"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onDetail}
    >
      <div className="_Left flex flex-col gap-y-1">
        <div className="flex w-full flex-row justify-between gap-x-1">
          <div className="text-[14px] font-semibold">{graph.name}</div>
        </div>
        <div className="_Properties flex flex-col gap-y-0.25">
          <GraphStatusView status={graph.status} />
        </div>
      </div>
      {hover && (
        <div className="_Right flex flex-row items-center gap-x-1">
          <Button
            label="Connect"
            onClick={(e: any) => {
              e.stopPropagation();
              onConnect();
            }}
            type={ButtonType.PRIMARY}
          ></Button>
          <Button
            label="View"
            onClick={(e: any) => {
              e.stopPropagation();
              onDetail();
            }}
            type={ButtonType.SECONDARY}
          ></Button>
        </div>
      )}
      <Modal open={connectOpen} onClose={handleClose}>
        <div className="text-[14px] font-medium">First, install Artifact's Python client:</div>
        <CodeBlock language="bash" code={`pip install artifact-client`} />
        <div className="text-[14px] font-medium">
          Then, use this code to connect to <a className="cursor-pointer font-semibold">{graph.name}</a> in Python:
        </div>
        <CodeBlock
          language="python"
          code={`from artifact import ArtifactClient\n
client = ArtifactClient("<your api key>")\n
graph = client.Graph("${graph.name}")`}
        />
        <div className="text-[14px] font-medium">
          For more information, please visit our{' '}
          <a
            className="cursor-pointer font-semibold text-ozoneV2-brand-blue-100"
            href="https://docs.useartifact.ai/guides/quickstart"
          >
            Quickstart Guide
          </a>{' '}
          or reads our{' '}
          <a
            className="cursor-pointer font-semibold text-ozoneV2-brand-blue-100"
            href="https://docs.useartifact.ai/reference"
          >
            Reference Docs
          </a>
          !
        </div>
      </Modal>
    </div>
  );
}
