import { useSelector } from 'react-redux';
import { selectCognitoUserName, selectUser } from '../../../stores/GlobalUser/slice/selectors';
import { Button } from '../Button/Button';
import { ButtonType } from '../Button/Button.types';
import useLogoutUser from '../../../stores/GlobalUser/hooks/useLogoutUser';

export function AccountModal() {
  const userName = useSelector(selectCognitoUserName);
  const user = useSelector(selectUser);
  const userEmail = user?.email ?? 'undefined';

  const { logout } = useLogoutUser();

  return (
    <div className="flex w-full flex-col gap-y-3">
      <div>{userName ? `Welcome, ${userName}!` : 'Welcome!'}</div>
      <div>Email: {userEmail}</div>
      <div>
        <Button label="Log out" onClick={logout} type={ButtonType.PRIMARY} />
      </div>
    </div>
  );
}
