import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectAppOrganizationId } from '../../../../../../../stores/GlobalApp/slice/selectors';
import { CheckIcon, CloudConnectIcon } from '../../../../../../components/Icons';
import { uploadFile } from '../../../../../../../helpers/api/uploadFile';
import { selectAllGraphs } from '../../../../../../../stores/GlobalGraphs/slice/selectors';
import { DocumentsList } from '../../../../../../components/DocumentsList/DocumentsList';
import { DocumentMetadata, DocumentUploadStatus } from '../../../../../../../types/documentMetadata';
import { v4 } from 'uuid';
import { notificationsActions } from '../../../../../../../stores/GlobalNotifications/slice';

const SUPPORTED_FILE_TYPES = [
  'application/pdf',
  'text/plain',
  'text/csv',
  'application/json',
  'application/xml',
  'text/xml',
];

export function GraphUploadData() {
  const dispatch = useDispatch();
  const organizationId = useSelector(selectAppOrganizationId);
  const { graphId } = useParams();
  const allGraphs = useSelector(selectAllGraphs);
  const graphName = useMemo(() => {
    return allGraphs.find(g => g.uuid === graphId)?.name;
  }, [allGraphs, graphId]);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [currentlyUploadingDocuments, setCurrentlyUploadingDocuments] = useState<Array<DocumentMetadata>>([]);

  const handleFileChange = async (file: File) => {
    if (!organizationId) return;
    if (!graphName) return;

    if (!SUPPORTED_FILE_TYPES.includes(file.type)) {
      alert('Unsupported file type. Please upload a PDF, TXT, CSV, XML, or JSON file.');
      return;
    }

    if (file) {
      setIsUploading(true);
      setUploadComplete(false);
      setUploadProgress(0);

      const sizeKb = Math.round(file.size / 1024);
      const uploadingMetadata: DocumentMetadata = {
        uuid: v4(),
        file_name: file.name,
        upload_time: new Date(),
        account_id: 'undefined', // Replace with the actual account ID if available
        size_bytes: sizeKb,
        status: DocumentUploadStatus.Uploading,
      };

      // Append the new document metadata to the list
      setCurrentlyUploadingDocuments(prevDocuments => [...prevDocuments, uploadingMetadata]);

      try {
        await uploadFile(organizationId, graphName, file, {}, progress => {
          setUploadProgress(progress);
          if (progress === 100) {
            setUploadComplete(true);
          }
        });
      } catch (error) {
        console.error('Upload failed', error);
      } finally {
        setIsUploading(false);
        setUploadProgress(0);
        setUploadComplete(true);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }

        // Update the document status to 'Uploaded'
        setCurrentlyUploadingDocuments(prevDocuments =>
          prevDocuments.map(doc =>
            doc.uuid === uploadingMetadata.uuid ? { ...doc, status: DocumentUploadStatus.Uploaded } : doc,
          ),
        );

        // Notify user
        dispatch(notificationsActions.addToast({ message: 'File upload completed and graph indexing scheduled' }));
      }
    }
  };

  const handleFiles = (files: FileList | null) => {
    if (files && files.length > 0) {
      Array.from(files).forEach(file => handleFileChange(file));
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleClick = () => {
    fileInputRef.current?.click(); // Trigger the file input click
  };

  return (
    <div className="mt-5 flex flex-col items-center justify-start gap-y-3">
      {/* Drag and Drop area */}
      <div
        className="h-64 flex w-full cursor-pointer flex-col items-center justify-center gap-y-2 rounded-[16px] border-2 border-dashed border-gray-300 p-5"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={handleClick}
      >
        <CloudConnectIcon className="size-6 text-gray-400" />
        <div>
          <p className="text-center text-[16px] font-medium text-gray-500">Drag and drop files here</p>
          <p className="text-center text-[12px] text-gray-500">Supported: .pdf, .csv, .txt, .xml, .json</p>
        </div>
      </div>

      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={e => handleFiles(e.target.files)}
        className="hidden"
        accept=".pdf, .txt, .csv, .json, .xml"
        multiple
      />

      {/* Uploading status */}
      {isUploading && (
        <div className="w-full max-w-lg">
          <div className="flex items-center justify-between">
            <p className="text-gray-600">Uploading files {uploadProgress}%</p>
            {uploadComplete ? (
              <CheckIcon className="size-6 text-green-500" />
            ) : (
              <div className="loader"></div> // Add a loading spinner or a simple CSS animation
            )}
          </div>
          {/* Progress bar */}
          <div className="mt-2 h-4 w-full rounded-full bg-gray-200">
            <div className="h-4 rounded-full bg-blue-600" style={{ width: `${uploadProgress}%` }}></div>
          </div>
        </div>
      )}

      {/* List of documents */}
      <div className="size-full overflow-y-scroll">
        <DocumentsList currentlyUploadingDocuments={currentlyUploadingDocuments} />
      </div>
    </div>
  );
}
