import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import awsExports from './aws-exports';
import { Amplify } from 'aws-amplify';
import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from './stores/configureStore';
import { App } from './app';
import { Provider } from 'react-redux';

export const store = configureAppStore();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

Amplify.configure(awsExports);

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </HelmetProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
